import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";

import {GraphsDate} from "../../store/branches/graphs/stateTypes";
import HeaderSensorGraphs from "./components/HeaderSensorGraphs";
import SensorGraph from "../../components/GraphsChart/SensorGraph";
import GraphsAlert from "../../components/Alerts/graphs";
import {useCurrentSelection} from "../../hooks/useCurrentSelection";
import {selectGraphsState} from "../../store/selectors";
import {graphsAC} from "../../store/branches/graphs/actionCreators";
import {LoadingStatus} from "../../store/status";
import Spinner from "../../components/Spinner";
import F500NodesBlock from "../../components/F500NodesBlock";
import {useIsTablet} from "../../hooks/useIsTablet";
import {useGetSensorsGraphsArray} from "../../hooks/useGetSensorsGraphsArray";
import {WinStorage} from "../../services/AuthSrorage";

import classes from "./SensorGraphs.module.scss";

export const F500_DEVICE_TYPE = ["F500", "F500-UDF"];

const SensorGraphsPage: React.FC = () => {
    const dispatch = useDispatch();

    const {graphsData, liveData, status, isLivePage, f500Nodes} = useSelector(selectGraphsState);
    const {getExistNode, getExistSensor} = useGetSensorsGraphsArray();

    const {device} = useCurrentSelection();
    const isTablet = useIsTablet();

    const timescale = WinStorage.getTimescale();
    const isF500Device = F500_DEVICE_TYPE.includes(device?.device_type);

    const addNodeGraph = (node: number) => {
        const isExistNode = getExistNode(node);
        dispatch(graphsAC.addF500Graph({
            data: {
                action: isExistNode ? "delete" : "append",
                node: node.toString(),
                device_id: device?.id,
            },
            timescale: timescale || GraphsDate.day,
        }));
    };

    const addSensorGraph = (node: number, sensor: number) => {
        const isExistSensor = getExistSensor(sensor);
        dispatch(graphsAC.addF500Graph({
            data: {
                action: isExistSensor ? "delete" : "append",
                node: node.toString(),
                sensor: sensor.toString(),
                device_id: device?.id,
            },
            timescale: timescale || GraphsDate.day,
        }));
    };

    const onClearAllNodes = () => {
        dispatch(graphsAC.addF500Graph({
            data: {
                action: "clean",
                device_id: device?.id,
            },
            timescale: timescale || GraphsDate.day,
        }));
    };


    useEffect(() => {
        const id = setInterval(() => {
            if (timescale === GraphsDate.hour) {
                dispatch(graphsAC.fetchGraphsData({device_id: +device?.id, timescale: GraphsDate.hour}));
            }
        }, 30000);
        return () => {
            clearInterval(id);
        };

    }, [dispatch, timescale, device]);

    useEffect(() => {
        if (device) {
            dispatch(graphsAC.fetchGraphsData({device_id: +device?.id, timescale: GraphsDate.day}));

            if (isF500Device) {
                dispatch(graphsAC.fetchF500Nodes(device?.id));
            }
        }

        return () => {
            dispatch(graphsAC.clearGraphsState());
        };
    }, [dispatch, device, isF500Device]);

    if (status === LoadingStatus.LOADING) {
        return <Spinner/>;
    }


    return (
        <div className={classes.SensorGraphsWrap}>
            <GraphsAlert/>
            <HeaderSensorGraphs/>

            <div className={classes.container}>
                <div className={clsx(isF500Device && classes.f500GraphWrap)}>

                    <div className={clsx(isF500Device && classes.mapWrap, classes.mapBlock)}>
                        <SensorGraph sensorsGraphData={graphsData}
                                     liveData={liveData}
                                     sensorsGraphDataLoading={status}
                                     isLivePage={isLivePage}
                                     device={device}
                        />
                    </div>
                    {
                        isF500Device
                        &&
                        <div className={clsx(isTablet ? classes.f500GraphNodesTabletWrap : classes.f500GraphNodesWrap)}>
                            <F500NodesBlock F500Nodes={f500Nodes}
                                            addNodeGraph={addNodeGraph}
                                            addSensorGraph={addSensorGraph}
                                            handleClearAllNodes={onClearAllNodes}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SensorGraphsPage;
