import {WarningDeviceType, WarningSensor, WarningsSensors} from "../store/branches/sensors/stateTypes";

export const getCurrentWarning = (warningId: string, warning_sensors: WarningsSensors[], device: WarningDeviceType) => {
    let current: WarningSensor;
    warning_sensors?.forEach((item: any) => {
        if (device?.device_type === "F500-UDF") {
            item.groups && item?.groups.forEach((item: any) => {
                item?.sensors?.forEach((item: WarningSensor) => {
                        if (item.id === warningId) {
                            current = item;
                        }
                    },
                );
            });
        } else if (item.name.split(" ").includes("Alignment")) {
            item?.sensors?.forEach((item: WarningSensor) => {
                if (item.id === warningId) {
                    current = item;
                }
            });
        } else {
            item?.sensors?.forEach((item: WarningSensor) => {
                if (item.id === warningId) {
                    current = item;
                }
            });
        }
    });
    return current;
};