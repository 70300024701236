import React, {useState} from "react";
import {DatePicker, Form, Select} from "antd";

import {ReactComponent as Date} from "../../../assets/icons/date.svg";
import {CustomButton} from "../../../components/Button";
import SelectWrap from "../../../components/NotificationSelectWrap";

import {Site} from "../../../store/branches/sites/stateTypes";
import {Device} from "../../../store/branches/devices/stateTypes";
import {IFilterData} from "../index";

import classes from "../Notifications.module.scss";

const {Option} = Select;
const {RangePicker} = DatePicker;

interface NotificationsFormProps {
    form: any;
    isClearFilters: boolean;
    onClearFilters: () => void;
    handSelectDate?: (value: any, option: any) => void;
    handSelectSite?: (value: any, option: any) => void;
    handSelectDevice?: (value: any, option: any) => void;
    handSelectType?: (value: any, option: any) => void;
    sites?: Site[];
    devices?: Device[];
    searchData?: IFilterData
}

const TYPES = {
    alarm_off: "Alarm Cleared",
    alarm_on: "Alarm Detected",
    device_administration: "Device administration",
    device_offline: "Device offline",
    device_online: "Device online",
    maintenance: "Maintenance",
    power_up: "Device Power Up",
    site_administration: "Site administration",
    sn2_status_change: "SN2 Status Change",
    test: "Test Email",
    warning_off: "Warning Cleared",
    warning_on: "Warning Detected",
};

const NotificationsForm: React.FC<NotificationsFormProps> = ({
                                                                 form,
                                                                 isClearFilters,
                                                                 onClearFilters,
                                                                 handSelectDate,
                                                                 handSelectDevice,
                                                                 handSelectSite,
                                                                 handSelectType,
                                                                 sites,
                                                                 devices,
                                                                 searchData,
                                                             }) => {
    const [dates, setDates] = useState([]);

    const selectedSite = sites?.filter(item => item?.id === Number(searchData?.location))[0]?.title || null;
    const selectedDevice = devices?.filter(item => item?.id === Number(searchData?.device))[0]?.title || null;

    const disabledDate = (current: any) => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], "days") > 30;
        const tooEarly = dates[1] && dates[1].diff(current, "days") > 30;
        return tooEarly || tooLate;
    };

    return (
        <div>
            <Form name="notifications_form"
                  form={form}
                  initialValues={{remember: true}}
            >

                <div className={classes.dateIcon}>
                    <Date/>
                </div>

                <div className={classes.formWrap}>
                    <div className={classes.itemWrap}>
                        <div>
                            <SelectWrap title="Date" classNameTitle={classes.dataPickerTitle}>
                                <Form.Item name="date" className={classes.rangePicker}>
                                    <RangePicker onChange={handSelectDate}
                                                 inputReadOnly={true}
                                                 style={{width: "270px"}}
                                                 size={"large"}
                                                 disabledDate={disabledDate}
                                                 onCalendarChange={val => setDates(val)}
                                    />
                                </Form.Item>
                            </SelectWrap>
                        </div>

                        <div className={classes.select}>
                            <SelectWrap title="Site" className={classes.options}>
                                <Select onSelect={handSelectSite}
                                        value={selectedSite || "ALL"}
                                        size="large" placeholder="Select"
                                        style={{width: "270px"}}>
                                    <Option value={"All"}
                                            key={"select_all"}
                                            props={"All"}
                                    >
                                        All
                                    </Option>
                                    {sites?.map((item: Site) => (
                                            <Option
                                                value={item.id}
                                                key={item.id}
                                                props={item.id}
                                            >
                                                {item.title}
                                            </Option>
                                        ),
                                    )}
                                </Select>
                            </SelectWrap>
                        </div>
                    </div>

                    <div className={classes.itemWrap} style={{marginBottom: '24px'}}>
                        <div className={classes.select}>
                            <SelectWrap title="Device" className={classes.options}>
                                <Select size="large"
                                        placeholder="Select"
                                        onSelect={handSelectDevice}
                                        value={selectedDevice || "ALL"}
                                        style={{width: "270px"}}>
                                    <Option value={"All"}
                                            key={"select_all"}
                                            props={"All"}
                                    >
                                        All
                                    </Option>
                                    {devices && devices.map((item: any, index) => (
                                        <Option value={item.id}
                                                key={item.id}
                                                props={item.id}
                                        >
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </SelectWrap>
                        </div>

                        <div className={classes.select}>
                            <SelectWrap title="Type" className={classes.options}>
                                <Select size="large"
                                        placeholder="Select"
                                        onSelect={handSelectType}
                                        value={searchData?.event_type || "ALL"}
                                        style={{width: "270px"}}>
                                    <Option value={"All"}
                                            key={"select_all"}
                                            props={"All"}
                                    >
                                        All
                                    </Option>
                                    {Object.entries(TYPES).map(([value, label]: any, index) => (
                                        <Option value={value}
                                                key={value}
                                                props={value}
                                        >
                                            {label}
                                        </Option>
                                    ))}
                                </Select>
                            </SelectWrap>
                        </div>
                    </div>


                    <div className={classes.button}>
                        {
                            isClearFilters && (
                                <Form.Item>
                                    <CustomButton width="110px"
                                                  height="40px"
                                                  padding="2px 2px"
                                                  fontSize="13px"
                                                  color="gray"
                                                  htmlType="submit"
                                                  onClick={onClearFilters}
                                    >
                                        <span>CLEAR FILTER</span>
                                    </CustomButton>
                                </Form.Item>
                            )
                        }
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default NotificationsForm;
