import React from "react";
import {Divider} from "antd";
import {F500Nodes} from "../../store/branches/graphs/stateTypes";
import {CustomButton} from "../Button";
import NodeBlock from "./components/NodeBlock";

import classes from "./F500NodesBlock.module.scss";

interface F500NodesBlockProps {
    F500Nodes: F500Nodes[]
    addSensorGraph: (node: number, sensor: number) => void
    addNodeGraph: (value: number) => void
    handleClearAllNodes: () => void
}


const F500NodesBlock: React.FC<F500NodesBlockProps> = ({
                                                           F500Nodes,
                                                           addNodeGraph,
                                                           addSensorGraph,
                                                           handleClearAllNodes,
                                                       }) => {
    return (
        <div className={classes.wrap}>
            <div className={classes.header}>
                <div>
                    <span className={classes.title}>Sensors:</span>
                </div>
                <div>
                    <CustomButton color="gray"
                                  width="86px"
                                  height="24px"
                                  fontSize="12px"
                                  padding="0"
                                  onClick={handleClearAllNodes}
                    >
                        Clear All
                    </CustomButton>
                </div>
            </div>
            <Divider style={{margin: "0 0 15px 0"}}/>

            <div className={classes.container}>
                {
                    F500Nodes?.map((item: F500Nodes, index: number) => {
                        return (
                            <div key={`node_${item?.number}_${index}_${item?.formated_number}`}>
                                <NodeBlock formated_number={item?.formated_number}
                                           nodeId={item?.number}
                                           item={item}
                                           indexGraph={index}
                                           sensors={item?.sensors}
                                           addSensorGraph={addSensorGraph}
                                           addNodeGraph={addNodeGraph}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};
export default F500NodesBlock;
