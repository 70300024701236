import {call, put, takeLatest} from "redux-saga/effects";
import {LoadingStatus} from "../../status";
import {
    ConfirmUserActivationAI,
    GetUserActivationAI,
    SetChangePasswordAI,
    SetUserActivationPasswordAI,
    VerificationAT,
} from "./actionTypes";
import {verificationAC} from "./actionCreators";
import {VerificationApi} from "../../../services/api/verificationApi";
import {WinStorage} from "../../../services/AuthSrorage";
import {userAC} from "../user/actionCreators";


export function* getUserActivationRequest({payload}: GetUserActivationAI) {
    try {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_LOADING));
        const {data, status} = yield call(VerificationApi.getUserActivation, payload);
        if (status === 200) {
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_SUCCESS));
            yield put(verificationAC.setUserActivation(data));
        } else {
            return yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_ERROR));
        }
    } catch (error) {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_ERROR));
    }
}

export function* confirmUserActivationRequest({payload}: ConfirmUserActivationAI) {
    try {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_LOADING));
        const {status} = yield call(VerificationApi.confirmUserActivation, payload);
        if (status === 200) {
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_SUCCESS));
            yield put(verificationAC.setUserActivation({stage: 1}));
        } else {
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.CONFIRM_ACTIVATION_USER_ERROR));
        }
    } catch (error) {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.CONFIRM_ACTIVATION_USER_ERROR));
    }
}

export function* setUserActivationPasswordRequest({payload}: SetUserActivationPasswordAI) {
    try {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_LOADING));
        const {data, status} = yield call(VerificationApi.setUserActivationPassword, payload);
        if (status === 201) {
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_PASSWORD_SUCCESS));
            WinStorage.setToken(data.token);
            yield put(userAC.setAuthKey(data.token));
            yield put(userAC.fetchUserData());
            yield put(userAC.setUserLoadingStatus(LoadingStatus.LOADED));
        } else {
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.CONFIRM_ACTIVATION_USER_ERROR));
        }
    } catch (error) {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.CONFIRM_ACTIVATION_USER_ERROR));
    }
}

export function* setChangePasswordRequest({payload}: SetChangePasswordAI) {
    try {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_LOADING));
        const {status} = yield call(VerificationApi.setChangePassword, payload);
        if (status === 200) {
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_SUCCESS));
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.CHANGE_PASSWORD_SUCCESS));
        } else {
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_ERROR));
            yield put(verificationAC.setUserActivationStatus(LoadingStatus.CHANGE_PASSWORD_ERROR));
        }
    } catch (error) {
        yield put(verificationAC.setUserActivationStatus(LoadingStatus.CHANGE_PASSWORD_ERROR));
    }
}


export function* verificationSaga() {
    yield takeLatest(VerificationAT.GET_USER_ACTIVATION, getUserActivationRequest);
    yield takeLatest(VerificationAT.CONFIRM_USER_ACTIVATION, confirmUserActivationRequest);
    yield takeLatest(VerificationAT.SET_USER_ACTIVATION_PASSWORD, setUserActivationPasswordRequest);
    yield takeLatest(VerificationAT.SET_CHANGE_PASSWORD, setChangePasswordRequest);
}
