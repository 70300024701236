import {axios} from "../axios_verification_config";

export const VerificationApi = {
    async setUserActivationPassword(payload: { token: string, password1: string, password2: string }): Promise<any> {
        const {data, status} = await axios.post<Promise<any>>(`api/v1/users/activation/set-password/${payload.token}/`, {
            password1: payload.password1,
            password2: payload.password2,
        });
        return {data, status};
    },
    async confirmUserActivation(payload: { token: string, stage: 1 }): Promise<any> {
        const {data, status} = await axios.post<Promise<any>>(`api/v1/users/activation/accept-terms/${payload.token}/`, {stage: 1});
        return {data, status};
    },
    async getUserActivation(token: string): Promise<any> {
        const {data, status} = await axios.get<Promise<any>>(`api/v1/users/activation/get-state/${token}`);
        return {data, status};
    },
    async setChangePassword(payload: {
        new_password1: string,
        new_password2: string,
        uid: string,
        token: string
    }): Promise<any> {
        const {data, status} = await axios.post<Promise<any>>(`api/v1/accounts/password/reset/confirm/`, payload);
        return {data, status};
    },
};
