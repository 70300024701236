import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, Form, Select, Spin, Table, Typography} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import cn from "classnames";

import {LoadingStatus} from "../../store/status";
import {AssignUser, SetMemberShip, UpdateMemberShip} from "../../store/branches/sites/stateTypes";
import {ReactComponent as EditUserIcon} from "../../assets/icons/edit_user.svg";
import {ReactComponent as DeleteUserIcon} from "../../assets/icons/delete_gray.svg";
import {ReactComponent as CheckedIcon} from "../../assets/icons/cheched.svg";
import {tableGenerationAssignUsers} from "./components/TableGeneration";
import {sitesAC} from "../../store/branches/sites/actionCreators";

import {selectSitesState} from "../../store/selectors";
import {CustomButton} from "../Button";
import classes from "./AssignUsersTable.module.scss";
import SitesAlert from "../Alerts/sites";
import {ApiConfig} from "../../services/config";

const {Option} = Select;
const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

interface TableProps {
    users: AssignUser[];
}

const AssignUserTable: React.FC<TableProps> = ({users}) => {
    const [form] = Form.useForm();
    const {id}: any = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const {status} = useSelector(selectSitesState);
    const assignUsers = tableGenerationAssignUsers(users, id);

    const onActivationLink = (event, id: number) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(sitesAC.sendActivationLink(id));
    };

    const onRemoveAssignUser = (assign_user_id: number) => {
        dispatch(sitesAC.removeAssignUser({assign_user_id, location_id: id, isUpdateUserPage: false}));
    };

    const handleChangeUser = (id: string) => {
        history.push(`/sites/edit/assign/user/${id}`);
    };

    const onCheckbox = (event: any, record: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (record.membership_id === undefined) {
            const data: SetMemberShip = {
                location: +id,
                user: record.id,
                permission: record.permission || "User",
                has_access: event.target.checked,
            };
            dispatch(sitesAC.setLocationMemberShip(data));
            return;
        }

        const data: UpdateMemberShip = {
            location_id: +id,
            id: record.membership_id,
            permission: record.permission || "User",
            has_access: event.target.checked,
        };
        dispatch(sitesAC.updateLocationMemberShip(data));
    };

    const onSelect = (record: any, value: any) => {
        if (record.membership_id === undefined) {
            const data: SetMemberShip = {
                location: +id,
                user: record.id,
                permission: value,
                has_access: record.hasAccess || false,
            };
            dispatch(sitesAC.setLocationMemberShip(data));
            return;
        }
        const data: UpdateMemberShip = {
            location_id: +id,
            id: record.membership_id,
            permission: value,
            has_access: record.hasAccess || false,
        };
        dispatch(sitesAC.updateLocationMemberShip(data));
    };

    const columns = [
        {
            title: "FULL NAME",
            dataIndex: "fullName",
            render: (_: any, record: any) => {
                return (
                    <Typography.Text ellipsis={true} className={classes.fullName}>
                        {record.fullName}
                    </Typography.Text>
                );
            },
        },
        {
            title: "EMAIL ADDRESS",
            dataIndex: "emailAddress",
            render: (_: any, record: any) => {
                return (
                    <Typography.Text ellipsis={true} className={classes.emailAddress}>
                        {record.emailAddress}
                    </Typography.Text>
                );
            },
        },
        {
            title: "COMPANY",
            dataIndex: "company",
            render: (_: any, record: any) => {
                return (
                    <Typography.Text ellipsis={true} className={classes.company}>
                        {record.company}
                    </Typography.Text>
                );
            },
        },
        {
            title: "PHONE NUMBER",
            dataIndex: "phoneNumber",
            render: (_: any, record: any) => {
                return (
                    <Typography.Text ellipsis={true} className={classes.phoneNumber}>
                        {record.phoneNumber}
                    </Typography.Text>
                );
            },
        },
        {
            title: "ACTIVATION LINK",
            dataIndex: "activationKey",
            render: (_: any, record: any) => {
                const apiSeverUrl = ApiConfig.getServerApiUrl();
                const width = window.innerWidth;
                const text = `${apiSeverUrl}accounts/activate/${record?.activationKey}`;
                const handleClick = () => {
                    dispatch(sitesAC.setOperationStatusSite(LoadingStatus.ACTIVATION_LINK_COPIED));
                    navigator.clipboard.writeText(text);
                };
                return (
                    <div className={classes.activationLink}>
                        {
                            width > 1100
                                ?
                                <span className={cn(record?.activationKey ? classes.link : classes.hideLink)}
                                      onClick={handleClick}>
                                    {text}
                                </span>
                                :
                                <Typography.Text ellipsis={true}>
                                    <span onClick={handleClick}
                                          className={cn(record?.activationKey ? classes.link : classes.hideLink)}>
                                        {text}
                                    </span>
                                </Typography.Text>
                        }
                    </div>
                );
            },
        },
        {
            title: "",
            key: "Send activation email",
            render: (_: any, record: any) => {
                if (record?.isActive) {
                    return (
                        <div className={classes.activatedButton}>
                            <span className={classes.activated}>Activated</span>
                            <CheckedIcon style={{marginBottom: '2px'}}/>
                        </div>
                    );
                }
                return (
                    <div className={classes.buttonsGroup}>
                        <CustomButton color={"client"}
                                      height={"22px"}
                                      width={"100%"}
                                      padding={"1px"}
                                      fontSize={"10px"}
                                      onClick={(event) => onActivationLink(event, record.id)}>
                            Send activation email
                        </CustomButton>
                    </div>
                );
            },
        },
        {
            title: "HAS ACCESS",
            dataIndex: "hasAccess",
            maxWidth: "10%",
            render: (_: any, record: any) => {
                return (
                    <Checkbox onChange={(event) => onCheckbox(event, record)}
                              checked={record.hasAccess}
                    />
                );
            },
        },
        {
            title: "PERMISSION",
            dataIndex: "permission",
            render: (_: any, record: any) => {
                return (
                    <div className={classes.permission}>
                        <Select value={record.permission || "User"}
                                style={{width: 97}}
                                onChange={(value) => onSelect(record, value)}
                                className={classes.column}
                        >
                            <Option value="User">User</Option>
                            <Option value="Engineer">Engineer</Option>
                            <Option value="Manager">Manager</Option>
                        </Select>
                    </div>
                );
            },
        },
        {
            title: "",
            dataIndex: "edit",
            render: (_: any, record: any) => {
                return (
                    <span onClick={() => {
                        handleChangeUser(record.id);
                    }}>
                        <EditUserIcon style={{cursor: "pointer"}}/>
                    </span>
                );
            },
        },
        {
            title: "",
            dataIndex: "delete",
            render: (_: any, record: any) => {
                return (
                    <span onClick={() => {
                        onRemoveAssignUser(record.id);
                    }}>
                        <DeleteUserIcon style={{cursor: "pointer"}}/>
                    </span>
                );
            },
        },
    ];

    const mergedColumnsAssignUsers = columns.map((col) => {
        return {
            ...col,
            onCell: () => ({
                title: col.title,
            }),
        };
    });

    if (status === LoadingStatus.LOADING) {
        return <Spin indicator={antIcon} className={classes.spin}/>;
    }

    return (
        <div className={classes.wrap}>
            <SitesAlert/>
            <Form form={form} component={false}>
                <Table pagination={false}
                       dataSource={assignUsers}
                       columns={mergedColumnsAssignUsers}
                />
            </Form>
        </div>
    );
};

export default AssignUserTable;
