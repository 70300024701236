import {useLayoutEffect, useState} from "react";

export const useIsTablet = () => {
    const [isTablet, setIsTablet] = useState<boolean>(false);

    useLayoutEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1000) {
            setIsTablet(true);
        }
    }, [setIsTablet]);

    return isTablet
};
