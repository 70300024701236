import baseService from "./baseService";
import {WinStorage} from "./AuthSrorage";
import axios from "axios";

export function getCurrentUser() {
    const jwt = WinStorage.getToken();
    if (!baseService.defaults.headers.common.Authorization) {
        baseService.defaults.headers.common.Authorization = `JWT ${jwt}`;
    }
    return baseService.get("/me/");
}

export function getLayoutSettings() {
    return axios.get("api/v1/layout-settings/");
}

export function getFlatPages() {
    return baseService.get("/get-flatpages/");
}

export function logout() {
    return baseService.post("/rest-auth/logout/");
}
