import React, {useEffect} from "react";
import InputWrap from "../../InputWrap";
import {Form, Select} from "antd";

import {ImageTypes, SensorImage} from "../../../store/branches/sensors/stateTypes";
import CustomOption from "./CustomOption";
import sensor_default from "../../../assets/icons/sensor_default.svg";

import classes from "../EditSensorsImages.module.scss";

interface SensorFormProps {
    sensorImage?: SensorImage;
    imageTypes?: ImageTypes[];
    sensorId?: number;
    form?: any;
}

const {Option} = Select;

const SensorForm: React.FC<SensorFormProps> = ({
                                                   sensorImage,
                                                   form,
                                                   imageTypes,
                                                   sensorId,
                                               }) => {
    const upperStr = (str: string) => {
        if (!str) return "No Image";

        return str[0].toUpperCase() + str.slice(1);
    };

    const getImageByType = (value: any) => {
        const imageUrl = imageTypes?.filter(item => item?.id === Number(value));
        return imageUrl[0];
    };

    useEffect(() => {
        form.setFieldsValue(sensorImage);
    }, [sensorImage, form]);

    return (
        <React.Fragment>
            <div className={classes.driver}>
                <span>{sensorImage?.name}</span>
                <div className={classes.cssBaseLine}/>
            </div>

            <div className={classes.blocksWrap}>
                {
                    sensorImage?.sensors?.map((item, index) => {

                        const isExistImage = typeof Object.values(item)[0] === "number";
                        const imageId = Object.values(item)[0];

                        return (
                            <InputWrap title={`${item?.name}` || `${sensorImage?.name}.${index + 1}`}
                                       classNameTitle={classes.inputWrapTitle}
                                       key={`input_sensor_name${item.id}_${index}`}
                            >
                                <Form.Item
                                    name={`${sensorId}.${Object.keys(item)[0]}`}
                                    initialValue={
                                        isExistImage
                                            ?
                                            <CustomOption imageUrl={getImageByType(imageId)?.thumbs?.small}
                                                          text={getImageByType(imageId)?.name}
                                            />
                                            :
                                            <CustomOption imageUrl={sensor_default} text={"No Image"}/>
                                    }
                                >

                                    <Select size="large">

                                        <Option value={null}
                                                key={`input_sensor_default_${item?.id}_${index + 1}`}>
                                            <CustomOption imageUrl={sensor_default}
                                                          text={"Default image"}
                                            />
                                        </Option>
                                        {imageTypes?.map((item: ImageTypes, index: number) => (

                                                <Option value={item?.id} key={`${item?.id}_${index}`}>
                                                    <CustomOption imageUrl={item?.thumbs?.small}
                                                                  text={upperStr(item?.name)}
                                                    />
                                                </Option>
                                            ),
                                        )}
                                    </Select>
                                </Form.Item>
                            </InputWrap>
                        );
                    })
                }
            </div>
        </React.Fragment>
    );
};
export default SensorForm;
