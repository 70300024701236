import {Action} from "redux";
import {LoadingStatus} from "../../status";

export enum VerificationAT {
    GET_USER_ACTIVATION = "user/GET_USER_ACTIVATION",
    CONFIRM_USER_ACTIVATION = "user/CONFIRM_USER_ACTIVATION",
    SET_USER_ACTIVATION = "user/SET_USER_ACTIVATION",
    SET_USER_ACTIVATION_STATUS = "user/SET_USER_ACTIVATION_STATUS",
    SET_USER_ACTIVATION_PASSWORD = "user/SET_USER_ACTIVATION_PASSWORD",
    SET_CHANGE_PASSWORD = "user/SET_CHANGE_PASSWORD",

}

export interface GetUserActivationAI extends Action<VerificationAT> {
    type: VerificationAT.GET_USER_ACTIVATION;
    payload: string;
}

export interface ConfirmUserActivationAI extends Action<VerificationAT> {
    type: VerificationAT.CONFIRM_USER_ACTIVATION;
    payload: { token: string, stage: 1 };
}

export interface SetUserActivationAI extends Action<VerificationAT> {
    type: VerificationAT.SET_USER_ACTIVATION;
    payload: { stage: 0 | 1 };
}

export interface SetUserActivationStatusAI extends Action<VerificationAT> {
    type: VerificationAT.SET_USER_ACTIVATION_STATUS;
    payload: LoadingStatus;
}

export interface SetUserActivationPasswordAI extends Action<VerificationAT> {
    type: VerificationAT.SET_USER_ACTIVATION_PASSWORD;
    payload: { token: string, password1: string, password2: string };
}

export interface SetChangePasswordAI extends Action<VerificationAT> {
    type: VerificationAT.SET_CHANGE_PASSWORD;
    payload: {
        new_password1: string,
        new_password2: string,
        uid: string,
        token: string
    };
}


