import axios from "axios";
import {WinStorage} from "./AuthSrorage";
import {ApiConfig} from "./config";

axios.interceptors.request.use((config) => {
    const apiSeverUrl = ApiConfig.getServerApiUrl();
    config.baseURL = apiSeverUrl;
    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 400) {
        const message: any = Object.values(error.response.data)[0];
        WinStorage.setErrorMessage(message);
    }
    return error;
});
export {axios};
