import React, {useState} from "react";
import {Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import axios from "axios";
import {CustomButton} from "../Button";

import {userAC} from "../../store/branches/user/actionCreators";
import {LoadingStatus} from "../../store/status";
import {selectUserState} from "../../store/selectors";
import ReCAPTCHA from "react-google-recaptcha";
import classes from "./Register.module.scss";

const site_Key = process.env.REACT_APP_CAPTCHA_SITE_KEY;


export interface RegisterValues {
    name: string
    company: string
    email: string
}

const RegisterForm: React.FC = () => {
    const [form] = useForm();
    const dispatch = useDispatch();

    const {status} = useSelector(selectUserState);
    const isDisabled = status === LoadingStatus.LOADING;
    const [captchaToken, setCaptchaToken] =  useState<string | null>(null);
    const [captchaVerified, setCaptchaVerified] = useState(false);

    const handleCaptchaChange = (token: string | null) => {
        if (token) {
          setCaptchaToken(token);
          setCaptchaVerified(true);
        } else {
          setCaptchaToken(null);
          setCaptchaVerified(false);
        }
      };

    const onSubmit = async (values: RegisterValues) => {
        if (!captchaVerified) {
            alert("Please complete the reCAPTCHA!");
            return;
        }

         try {
            const response = await axios.post('api/v1/verify-recaptcha/', {
              token: captchaToken
                }, {
                  headers: {
                    "Content-Type": "application/json", // Ensure the correct content type
                  }
                });

            if (response.data.success) {
                dispatch(userAC.signUp(values));
                form.resetFields();
            } else {
                alert("CAPTCHA verification failed!");
            }
        } catch (error) {
            console.error("Error verifying reCAPTCHA:", error);
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <React.Fragment>
            <Form name="register"
                  className="login-form"
                  form={form}
                  initialValues={{remember: true}}
                  onFinish={onSubmit}>

                <div className={classes.inputTitle}>Full Name*</div>
                <Form.Item name="name"
                           rules={[{
                               required: true,
                               message: "Full Name!",
                           }]}>
                    <Input placeholder="Full Name" className={classes.loginInput}/>
                </Form.Item>

                <div className={classes.inputTitle}>Company Name*</div>
                <Form.Item name="company"
                           rules={[{
                               required: true,
                               message: "Please input your Company Name!",
                           }]}>
                    <Input placeholder="Company Name" className={classes.loginInput}/>
                </Form.Item>

                <div className={classes.inputTitle}>Email Address*</div>
                <Form.Item name="email"
                           rules={[
                               {
                                   type: "email",
                                   message: "The input is not valid E-mail!",
                               },
                               {
                                   required: true,
                                   message: "Please input your Email Address!",
                               }]}>
                    <Input placeholder="Email Address" className={classes.loginInput}/>
                </Form.Item>
                <div className={classes.captcha}>
                    <ReCAPTCHA
                        sitekey={site_Key}
                        onChange={handleCaptchaChange}
                    />
                </div>
                <Form.Item>
                    <CustomButton width="100%"
                                  htmlType="submit"
                                  disabled={isDisabled || !captchaVerified}
                                  className={classes.button}
                    >
                        REGISTER
                    </CustomButton>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};
export default RegisterForm;
