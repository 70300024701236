import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "antd";

import {LoadingStatus} from "../../store/status";
import {WinStorage} from "../../services/AuthSrorage";
import {selectVerificationState} from "../../store/selectors";

import classes from "./Alert.module.scss";
import {verificationAC} from "../../store/branches/verification/actionCreators";

type AlertNotification = "success" | "info" | "warning" | "error"


const VerificationAlert = () => {
    const {userActivationStatus} = useSelector(selectVerificationState);
    const dispatch = useDispatch();
    const errorMessage = WinStorage.getErrorMessage();

    const setUserActivationTimer = () => {
        setTimeout(() => {
            dispatch(verificationAC.setUserActivationStatus(LoadingStatus.ACTIVATION_USER_PENDING));
            WinStorage.removeErrorMessage();
        }, 6000);
    };

    const userActivationAlertNotification = (message: typeof errorMessage | string, type: AlertNotification) => {
        return <Alert
            message={message}
            type={type}
            showIcon
            closable
            className={type === "success" ? classes.alert : classes.userActivationAlert}
        />;
    };


    if (userActivationStatus === LoadingStatus.ACTIVATION_USER_ERROR) {
        setUserActivationTimer();
        return userActivationAlertNotification(errorMessage || "Sorry, the activation link is invalid.", "error");
    }

    if (userActivationStatus === LoadingStatus.CONFIRM_ACTIVATION_USER_ERROR) {
        setUserActivationTimer();
        return userActivationAlertNotification(errorMessage || "Sorry, something went wrong. Please try again later.", "error");
    }

    if (userActivationStatus === LoadingStatus.ACTIVATION_USER_PASSWORD_SUCCESS) {
        setUserActivationTimer();
        return userActivationAlertNotification("Activation was successful", "success");
    }

    if (userActivationStatus === LoadingStatus.CHANGE_PASSWORD_SUCCESS) {
        setUserActivationTimer();
        return userActivationAlertNotification("Password has been reset with new password", "success");
    }

    if (userActivationStatus === LoadingStatus.CHANGE_PASSWORD_ERROR) {
        setUserActivationTimer();
        return userActivationAlertNotification("The password reset link was invalid, possibly because it has already been used", "error");
    }

    return null;
};
export default VerificationAlert;
