import axios from "axios";
import qs from "qs";
import cookies from "./cookies";
import {WinStorage} from "./AuthSrorage";
import {parseResponseErrors} from "../helpers/helperFunctions";
import {ApiConfig} from "./config";

export const apiSeverUrl = ApiConfig.getServerApiUrl();
export const WSSeverUrl = ApiConfig.getServerWssUrl();

const baseService = axios.create({
    baseURL: `${apiSeverUrl}api/v2`,
    paramsSerializer: (params) => {
        return qs.stringify(params, {indices: false, arrayFormat: "comma"});
    },
});

const jwt = WinStorage.getToken();

export const getCSRFToken = () => {
    const elements = document.getElementsByName("csrfmiddlewaretoken")[0];
    return cookies.get("csrftoken") || (elements && (elements as HTMLInputElement).value);
};

baseService.defaults.headers.common["X-CSRFToken"] = getCSRFToken();


if (jwt) {
    baseService.defaults.headers.common.Authorization = `JWT ${jwt}`;
}

baseService.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 400) {
        alert(parseResponseErrors(error))
        console.log(parseResponseErrors(error));
    }
    return error;
});

export default baseService;
