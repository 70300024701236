import React from "react";
import {Checkbox, Divider} from "antd";
import clsx from "clsx";

import {F500Nodes, f500Sensor} from "../../../store/branches/graphs/stateTypes";
import {useGetSensorsGraphsArray} from "../../../hooks/useGetSensorsGraphsArray";

import classes from "../F500NodesBlock.module.scss";

interface NodeBlockProps {
    nodeId?: number
    indexGraph?: number
    formated_number?: number
    sensors?: f500Sensor[]
    item?: F500Nodes
    addSensorGraph: (node: number, sensor: number) => void
    addNodeGraph: (value: number) => void
}


const NodeBlock: React.FC<NodeBlockProps> = ({
                                                 formated_number,
                                                 indexGraph,
                                                 item,
                                                 nodeId,
                                                 sensors,
                                                 addNodeGraph,
                                                 addSensorGraph,
                                             }) => {
    const colorGraph = ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
        '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a']

    const {getExistSensor, getExistNode} = useGetSensorsGraphsArray();

    const isCheckedNode = getExistNode(nodeId)

    const handleCheckNode = (node: number) => {
        addNodeGraph(node);
    };

    const handleCheckSensor = (sensor: number) => {
        addSensorGraph(nodeId, sensor);
    };

    return (
        <div>
            <div className={classes.checkboxNumber}>
                <div className={classes.checkboxWrap}>
                    <Checkbox checked={isCheckedNode} onChange={() => handleCheckNode(nodeId)}
                              className={classes.checkbox}/>
                </div>
                <div className={classes.title}>
                    Node {formated_number}
                </div>
            </div>
            <Divider style={{margin: "5px 0"}}/>
            <div className={classes.nodeBlockWrap}>

                {
                    sensors?.map((sensor: f500Sensor, index: number) => {
                        const checkedSensor = getExistSensor(sensor?.id)
                        return (
                            <div key={`sensor_${sensor?.id}_${index}`} className={classes.checkboxBlock}>
                                <div className={classes.checkboxNumber}>
                                    <div className={classes.checkboxWrap}>
                                        <Checkbox checked={checkedSensor} onChange={() => handleCheckSensor(sensor?.id)}
                                                  className={classes.checkbox}/>
                                    </div>
                                    <div>
                                        {formated_number}.{index + 1} - {sensor?.name}
                                    </div>
                                </div>
                                <div className={clsx(checkedSensor && classes.color)}
                                     style={{backgroundColor: colorGraph[index]}}/>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default NodeBlock;
