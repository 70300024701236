import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useForm} from "antd/lib/form/Form";

import AddUserForm from "./components/AddUserForm";
import {useDispatch, useSelector} from "react-redux";
import {sitesAC} from "../../store/branches/sites/actionCreators";
import {LoadingStatus} from "../../store/status";
import Preloader from "../Preloader";
import {selectSitesState, selectUserState} from "../../store/selectors";
import {Site, SiteAccess, UserData} from "../../store/branches/sites/stateTypes";
import {usePermissions} from "../../hooks/usePermissions";


const AddUser: React.FC = () => {
    const [locationAccess, setAccessById] = useState<SiteAccess[]>([]);
    const [form] = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const {id}: any = useParams();
    const {site_access, status, current_assign_user} = useSelector(selectSitesState);
    const {userData} = useSelector(selectUserState)
    const {hasAccessToEdit} = usePermissions();
    const siteAccess = site_access?.results;

    const onSubmit = (values: any) => {
        const new_location_access = locationAccess.map(item => ({
            location_id: item.id,
            has_access: item.has_access,
            permission: item.permission,
        }));

        const data: UserData = {
            full_name: values.full_name,
            company: values.company,
            phone: values.phone,
            email: values.email,
            location_access: new_location_access,
            address: values.address,
        };

        if (current_assign_user) {
            dispatch(sitesAC.updateAssignUser({assign_user_id: id, data}));
            return;
        }
        dispatch(sitesAC.setUser(data));
        form.resetFields();
    };

    const onCancel = () => {
        form.resetFields();
        history.push(`/sites/edit/${userData?.current_location?.id}`);
    };

    const onSavePermission = (id: number, permission: SiteAccess["permission"]) => {
        setAccessById(
            locationAccess.map(item =>
                item.id === id
                    ? {...item, permission: permission}
                    : item,
            ));
    };


    const onSaveHasAccess = (id: number, has_access: boolean) => {
        setAccessById(
            locationAccess.map(item =>
                item.id === id
                    ? {...item, has_access: has_access}
                    : item,
            ));
    };

    useEffect(() => {
        dispatch(sitesAC.fetchSiteAccess());
    }, [dispatch]);

    useEffect(() => {
        if (siteAccess) {
            if (id) {
                const getDefaultAccessValue = (item: Site) => {
                    const currentAccessValue = current_assign_user?.location_access.find((access) => access?.location_id === item?.id);
                    return currentAccessValue?.has_access || false;
                };

                const getDefaultPermissionValue = (item: Site) => {
                    const currentAccessValue = current_assign_user?.location_access.find((access) => access?.location_id === item?.id);
                    return currentAccessValue?.permission || "User";
                };
                const data = siteAccess.map((item) => (
                    {
                        id: item?.id,
                        site: item?.title,
                        has_access: getDefaultAccessValue(item),
                        permission: getDefaultPermissionValue(item),
                    }),
                );
                setAccessById(data);
            }
        }
    }, [siteAccess, current_assign_user, id]);

    useEffect(() => {
        if (siteAccess && !id) {
            siteAccess.map((item) => setAccessById((prev) =>
                [...prev, {
                    id: item?.id,
                    site: item?.title,
                    has_access: false,
                    permission: "User",
                }],
            ));
        }

        return () => {
            setAccessById([]);
        };
    }, [siteAccess, id]);

    useEffect(() => {
        if (id) {
            dispatch(sitesAC.fetchCurrentAssignUser(id));
        }

        return () => {
            dispatch(sitesAC.clearCurrentUser());
        };
    }, [dispatch, id]);

    if (!hasAccessToEdit) {
        history.push("/sites");
    }


    return (
        <Preloader isLoaded={status === LoadingStatus.LOADING}>
            <React.Fragment>

                <AddUserForm onSubmit={onSubmit}
                             onCancel={onCancel}
                             onSavePermission={onSavePermission}
                             onSaveHasAccess={onSaveHasAccess}
                             form={form}
                             locationAccess={locationAccess}

                />
            </React.Fragment>
        </Preloader>
    );
};

export default AddUser;
