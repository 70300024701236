import React from "react";
import {Alert, Typography} from "antd";
import {Link, useParams} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";

import {useCurrentSelection} from "../../../hooks/useCurrentSelection";
import {usePermissions} from "../../../hooks/usePermissions";
import SettingPopup from "../../../components/Setting";

import classes from "../SensorDashboard.module.scss";

const {Title} = Typography;

const HeaderSection: React.FC<{ isSnapshot: boolean }> = ({isSnapshot}) => {
    const {client, site, device} = useCurrentSelection();
    const {hasAccessToEdit} = usePermissions();
    const {time}: any = useParams();

    return (
        <React.Fragment>
            <div className="block-title">
                <Title level={5}>
                    <Link to="/sites">{client?.company || "Client"} / </Link>
                    <Link to="/devices">{site?.title || "Sites"} </Link>
                </Title>
            </div>

            <div className={classes.infoWrap}>
                <div className={classes.headerTitle}>
                    <p className={classes.subtitle}>{device?.title || "Device"}</p>
                </div>

                <div className={classes.alertHistoricalWrap}>
                    {
                        (time && isSnapshot)
                        &&
                        <Alert message=""
                               description={`Warning: This is a historical snapshot. The data is not live. Snapshot date/time: ${time}`}
                               className={classes.alertHistorical}
                               type="error"
                               icon={<ExclamationCircleOutlined className={classes.icon}/>}
                               showIcon
                        />
                    }
                </div>

                {hasAccessToEdit && <SettingPopup/>}
            </div>
        </React.Fragment>
    );
};

export default HeaderSection;
