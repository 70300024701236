export const formatDuration = (duration: string) => {
    const parts = duration.split(" ");
    if(["On", "Off"].includes(duration)) return duration

    if (parts.length === 2) {
        const days = parts[0];
        let [hours, minutes, seconds] = parts[1].split(":");
        seconds = seconds ? seconds.split(".")[0] : seconds;
        const dayWord = days === "1" ? "day" : "days";
        return `${days} ${dayWord}, ${hours}:${minutes}:${seconds}`;
    } else if (parts.length === 1) {
        let [hours, minutes, seconds] = parts[0].split(":");
        seconds = seconds && seconds.includes(".") ? seconds.split(".")[0] : seconds;
        return `${hours}:${minutes}:${seconds}`;
    } else {
        return duration;
    }
}
