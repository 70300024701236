import React from "react";
import clsx from "clsx"

import classes from "./SelectWrap.module.scss";


interface EditProps {
    title?: string
    children?: any
    className?: any
    classNameTitle?: any
}


const SelectWrap: React.FC<EditProps> = ({title, children, className, classNameTitle}) => {
    return (
        <div className={clsx(classes.block, className)}>
            <div className={clsx(classes.title, classNameTitle)}>
                {title}
            </div>

            <div className={classes.input}>
                {children}
            </div>
        </div>
    )
}
export default SelectWrap
