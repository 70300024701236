let priorityF500 = {};
for (let inet = 3; inet >= 0; --inet) {
    priorityF500[(4 - inet) + ""] = {};
    for (let inode = 63; inode >= 0; inode--) {
        priorityF500["" + (4 - inet)]["" + ((4 - inet) * 100 + (63 - inode))] = inode + inet * 64;
    }
}

let IE_NODE_GRAPH_POSITIONS = {
    "0": {
        "1000": 10, // NTC
    },
    "getOrder": function (idArray) {
        let tmpObj = this[0]
        if (idArray.indexOf("sensors") !== -1) {
            // Separated sensors have biggest priority, apparently...
            return 11;
        }
        for (let i = 0; i < idArray.length; i++) {
            tmpObj = tmpObj[idArray[i]]
            if (!tmpObj) {
                return 0
            }
        }
        return tmpObj
    }
}

export const GRAPH_POSIITONS = {
    "0300": {  // WDC-4
        "0": {
            "17000": 10, // system
            "12000": { // speed
                "15000": 9,
                "16000": 8
            },
            "1000": {  // alignment
                "2000": 7,
                "3000": 6
            },
            "24000": 5,
            "8000": 4,
            "10000": 3
        },
        "getOrder": function (idArray) {
            let tmpObj = this[0];
            if (idArray.indexOf("sensors") !== -1) {
                // Separated sensors have biggest priority, apparently...
                return 11;
            }
            for (let i = 0; i < idArray.length; i++) {
                tmpObj = tmpObj[idArray[i]];
                if (!tmpObj) {
                    // console.log("Order not found");
                    return 0
                }
            }
            return tmpObj
        }
    },
    "0005": IE_NODE_GRAPH_POSITIONS,  // IE-NODE
    "0006": IE_NODE_GRAPH_POSITIONS,  // IE-NODE-2
    "F500-UDF": {
        "0": priorityF500,
        "getOrder": function (idArray) {
            let tmpObj = this[0];
            if (idArray.indexOf("sensors") !== -1) {
                console.log("Separated sensors have biggest priority, apparently...");
                return 256; // 255 is the last of possible numbers from priority list. (64 * 4)
            }
            for (let i = 0; i < idArray.length; i++) {
                tmpObj = tmpObj[idArray[i]];
                if (!tmpObj) {
                    return 0
                }
            }
            return tmpObj
        }
    }
}
