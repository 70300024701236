import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {useParams} from "react-router-dom";
import _ from "lodash";
import {useDispatch} from "react-redux";
import Empty from "antd/lib/empty";

import {LoadingStatus} from "../../store/status";
import {GraphsState} from "../../store/branches/graphs/stateTypes";
import {graphsAC} from "../../store/branches/graphs/actionCreators";

import Spinner from "../Spinner";
import SensorGraphHeader from "../GraphsChartHeader/SensorGraphHeader";
import RenderGraph from "../../pages/SensorGraphs/components/RenderGraph";
import {ReactComponent as Info} from "../../assets/icons/info.svg";
import {F500_DEVICE_TYPE} from "../../pages/SensorGraphs";

import classes from "./SensorGraph.module.scss";
import {GRAPH_POSIITONS} from "../../helpers/graphOrder";


interface SensorGraphProps {
    sensorsGraphData?: GraphsState["graphsData"]
    liveData?: GraphsState["graphsData"]
    sensorsGraphDataLoading?: string
    isLivePage?: boolean
    device?: { id: number, title: string, udf_id: string, device_type: string }
}

const defaultOptions = {
    chart: {
        animation: false,
    },
    credits: {
        enabled: false,
    },
    legend: {
        enabled: true,
    },
    plotOptions: {
        line: {
            turboThreshold: 10000,
        },
        marker: {
            enabled: false,
        },
        series: {
            showInNavigator: true,
            turboThreshold: 0,
            animation: false,
        },
        shadow: false,
    },
    rangeSelector: {
        enabled: false,
        selected: 1,
    },
    scrollbar: {
        liveRedraw: false,
    },
    tooltip: {
        enabled: true,
    },
};


const SensorGraph: React.FC<SensorGraphProps> = ({
                                                     sensorsGraphData,
                                                     liveData,
                                                     sensorsGraphDataLoading,
                                                     isLivePage,
                                                     device,
                                                 }) => {
    const dispatch = useDispatch();
    const intervalId = useRef<NodeJS.Timeout>(null);
    const {id}: any = useParams();

    const fetchLiveData = useCallback(() => {
        dispatch(graphsAC.updateLiveGraphsData(device.id));
    }, [dispatch, device?.id]);


    useEffect(() => {
        if (isLivePage) {
            intervalId.current = setInterval(() => {
                fetchLiveData();
            }, 2000);
        } else {
            clearInterval(intervalId.current);
        }

        return () => {
            clearInterval(intervalId.current);
            dispatch(graphsAC.clearLiveData());
        };
    }, [isLivePage, fetchLiveData, dispatch]);

    const currentSensorGraphData: any = useMemo(() => {
        const data = isLivePage ? liveData : sensorsGraphData;

        let graphOrder: { sensorId: string, order: number, graphs: any }[] = [];

        for (let i in data?.graphs) {
            graphOrder.push({
                sensorId: i,
                "order": GRAPH_POSIITONS[data.device_type].getOrder(i.split(".")),
                graphs: data?.graphs[i],
            });
        }
        const ordered = graphOrder.sort(function (a, b) {
            return b.order - a.order;
        });

        let sortedData: any = {};
        ordered.forEach((item, i) => {
            sortedData = {...data, graphs: {...sortedData.graphs, [item.sensorId]: item.graphs}};
            return item;
        });

        if (!id) {
            return sortedData;
        }

        if (!data) {
            return {};
        }

        const graphs = Object.entries(data.graphs);

        for (let [nodeId, graph] of graphs) {
            const {series} = graph as any;

            for (let currentSeries of series) {
                const {sensor} = currentSeries;
                const [reducedSensorId] = sensor.toString().split(".").slice(-1);

                if (reducedSensorId === id) {
                    return {
                        ...data,
                        graphs: {
                            [nodeId]: {
                                ...graph as any,
                                series: [
                                    currentSeries,
                                ],
                            },
                        },
                    };
                }
            }
        }

        return {};
    }, [id, sensorsGraphData, isLivePage, liveData]);

    const isLostComms = (state: string) => {
        return state === "Lost Communication Between T500 and node" || state === "Sensor Not Scanned by T500" || state === "Disabled";
    };

    if (sensorsGraphDataLoading === LoadingStatus.LOADING) {
        return <Spinner/>;
    }

    const isF500Device = F500_DEVICE_TYPE.includes(device?.device_type);

    if (sensorsGraphData && Object.keys(sensorsGraphData?.graphs)?.length === 0) {
        if (isF500Device) {
            return (
                <div className={classes.emptyWrap}>
                    <SensorGraphHeader device_type={"F 500"}/>
                    <div className={classes.emptyCard}>
                        <Empty image={<Info/>} description="Please select Sensors to generate graphs!"  style={{color: "#7C90B1"}}/>
                    </div>
                </div>
            );
        }

        return <Empty description="graphs is empty!"/>;
    }


    if (isLivePage) {
        if (liveData) {

            return (
                <div className={classes.widget}>
                    <div className={classes.widgetLiveBody}>
                        {_.map(currentSensorGraphData?.graphs, (sensorGraphDataArray: any, index: number) => {
                            return (
                                <div className={classes.deviceBlock} key={`deviceName${index}`}>
                                    <RenderGraph sensorGraphDataInitial={sensorGraphDataArray.series}
                                                 groupName={sensorGraphDataArray.units}
                                                 sensorGraphDataArray={sensorGraphDataArray}
                                                 isLostComms={isLostComms}
                                                 deviceType={device?.device_type}
                                                 defaultOptions={defaultOptions}
                                                 sensorId={index}
                                    />
                                </div>);
                        })}
                    </div>
                </div>
            );
        } else {
            return <Spinner/>;
        }
    }

    return (
        <div className={classes.widget}>
            <div className={classes.widgetBody}>
                {_.map(currentSensorGraphData?.graphs, (sensorGraphDataArray: any, sensorId: number) => {
                    return (
                        <div className={classes.deviceBlock} key={`deviceName${sensorId}`}>
                            <RenderGraph sensorGraphDataInitial={sensorGraphDataArray.series}
                                         groupName={sensorGraphDataArray.units}
                                         sensorGraphDataArray={sensorGraphDataArray}
                                         isLostComms={isLostComms}
                                         deviceType={device?.device_type}
                                         defaultOptions={defaultOptions}
                                         sensorId={sensorId}
                            />
                        </div>);
                })}
            </div>
        </div>
    );
};

export default SensorGraph;
