import React from "react";
import clsx from "clsx";
import {Form, Typography} from "antd";
import {CustomButton} from "../../Button";
import {EditAssignUser} from "../../../store/branches/sites/stateTypes";

const {Title} = Typography;

interface HeaderFormProps {
    onCancel: () => void;
    currentAssignUser: EditAssignUser;
}

const HeaderFormAddUser: React.FC<HeaderFormProps> = ({onCancel, currentAssignUser}) => {
    return (
        <div className={clsx("d-flex", "d-flex-w")}>
            <div>
                <Title level={2}>
                    {currentAssignUser ? "Update User" : "Add a User"}
                </Title>
            </div>

            <div className="d-flex">
                <div>
                    {
                        currentAssignUser
                            ?
                            <Form.Item>
                                <CustomButton width="120px"
                                              height="40px"
                                              padding="2px 2px"
                                              fontSize="13px"
                                              className="mar-right-10"
                                              htmlType="submit"
                                >
                                    <span>SAVE CHANGES</span>
                                </CustomButton>
                            </Form.Item>
                            :
                            <Form.Item>
                                <CustomButton width="105px"
                                              height="40px"
                                              padding="2px 2px"
                                              fontSize="13px"
                                              className="mar-right-10"
                                              htmlType="submit"
                                >
                                    <span>ADD USER</span>
                                </CustomButton>
                            </Form.Item>
                    }
                </div>
                <div>
                    <Form.Item>
                        <CustomButton width="94px"
                                      height="40px"
                                      padding="2px 2px"
                                      color="gray"
                                      fontSize="13px"
                                      htmlType="button"
                                      onClick={onCancel}
                        >
                            <span>CANCEL</span>
                        </CustomButton>
                    </Form.Item>
                </div>
            </div>
        </div>
    );
};

export default HeaderFormAddUser;
