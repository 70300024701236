import React, {useEffect, useState} from "react";
import {Alert, Form, Input} from "antd";
import classes from "../Verification.module.scss";
import {useForm} from "antd/lib/form/Form";
import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {verificationAC} from "../../../store/branches/verification/actionCreators";
import {CustomButton} from "../../../components/Button";
import {RuleItem} from "./RuleItem";
import {CHANGE_PASSWORD} from "../../../components/PrivateRoute/components/constants";


const PasswordForm: React.FC = () => {
    const dispatch = useDispatch();
    const [form] = useForm();
    const {token} = useParams();
    const {pathname} = useLocation();
    const [emptyFields, setEmpty] = useState<boolean>(false);
    const [numError, setNumError] = useState<boolean>(false);
    const [upperError, setUpperError] = useState<boolean>(false);
    const [countError, setCountError] = useState<boolean>(false);

    const getShortPath = (value: string) => {
        return value.split("/").slice(1, 4).join("");
    };

    const getChangePasswordProperties = (value: string) => {
        const params = value.split("/").slice(4, 5)[0].split("-");
        const uid = params[0];
        const token = params.slice(1).join("-");

        return {uid, token};
    };

    const isChangePasswordPage = getShortPath(pathname) === getShortPath(CHANGE_PASSWORD);

    const onSubmit = (values: any) => {
        if (!values.new_password || !values.confirm) {
            return setEmpty(!values.new_password || !values.confirm);
        }
        // can't be entirely numeric
        const regexNumeric = new RegExp(/^(.*[0-9].*)$/);
        // should contain at least one upper case
        const regexUpper = new RegExp(/^(.*[A-Z].*)$/);
        // should contain at least 8 from the mentioned characters
        const regexAtList8 = new RegExp(/^.{8,}$/);

        if (values?.new_password && values?.confirm) {
            if (!regexNumeric.test(values.new_password)) {
                return setNumError(true);
            } else {
                setNumError(false);
            }

            if (!regexUpper.test(values.new_password)) {
                return setUpperError(true);
            } else {
                setUpperError(false);
            }

            if (!regexAtList8.test(values.new_password)) {
                return setCountError(true);
            } else {
                setCountError(false);
            }

            if (isChangePasswordPage) {
                const properties = getChangePasswordProperties(pathname);
                dispatch(verificationAC.setChangePassword({
                    new_password1: values.new_password,
                    new_password2: values.confirm,
                    ...properties,
                }));
            } else {
                dispatch(verificationAC.setUserActivationPassword({
                    token, password1: values.new_password,
                    password2: values.confirm,
                }));
            }
        }
    };

    useEffect(() => {
        if (emptyFields) {
            setTimeout(() => {
                setEmpty(false);
            }, 6000);
        }
    }, [emptyFields]);

    return (
        <div className={classes.passwordFormWrap}>
            {
                emptyFields && <Alert
                    message={"Fields cannot be empty!!"}
                    type={"error"}
                    showIcon
                    closable
                    className={classes.userActivationAlert}
                />
            }
            <div className={classes.mainTitleWrap}>
                <h2 className={classes.mainTitle}>{isChangePasswordPage ? "Change Password" : "Set your password"}</h2>
            </div>
            {
                !isChangePasswordPage &&
                <div className={classes.mainTitleWrap}>
                    <h3 className={classes.mainDesc}>Please set your password to activate your account</h3>
                </div>
            }

            <Form name="user_setting"
                  form={form}
                  initialValues={{remember: true}}
                  onFinish={onSubmit}
            >
                <div className={classes.container}>
                    <div className={classes.inputPasswordWrap}>
                        <div className={classes.inputTitleWrap}>
                            <span
                                className={classes.inputTitle}>{isChangePasswordPage ? "New Password" : "Password*"}</span>
                        </div>
                        <div>
                            <Form.Item
                                name="new_password"
                                hasFeedback
                            >
                                <Input type="password" className={classes.passwordInput} placeholder={"Password"}/>
                            </Form.Item>

                            <div>
                                <RuleItem error={numError}
                                          rule={"Your password can't be too similar to your other personal information"}/>
                                <RuleItem error={upperError}
                                          rule={"Your password must contain at least one upper case"}/>
                                <RuleItem error={countError} rule={"Your password must contain at least 8 characters"}/>
                                <RuleItem error={numError} rule={"Your password cant be a commonly used password"}/>
                                <RuleItem error={numError} rule={"Your password cant be entirely numeric"}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.inputPasswordWrap}>
                        <div className={classes.inputTitleWrap}>
                            <span
                                className={classes.inputTitle}>{isChangePasswordPage ? "New Password (again)*" : "Password Confirmation*"}</span>
                        </div>
                        <Form.Item
                            name="confirm"
                            dependencies={["new_password"]}
                            hasFeedback
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("new_password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("The two passwords that you entered do not match!"));
                                    },
                                }),
                            ]}
                        >
                            <Input type="password" className={classes.passwordInput} placeholder={"Password"}/>
                        </Form.Item>

                        <div>
                            <RuleItem error={false} rule={"Enter the same password as before, for verification"}/>
                        </div>
                    </div>
                </div>

                <div className={classes.buttonWrap}>
                    <Form.Item>
                        <CustomButton width="150px"
                                      height="40px"
                                      padding="2px 2px"
                                      fontSize="13px"
                                      htmlType="submit">
                            <span>{isChangePasswordPage ? "CHANGE PASSWORD" : "ACTIVATE ME"}</span>
                        </CustomButton>
                    </Form.Item>
                </div>
            </Form>

        </div>
    );
};

export default PasswordForm;
