import React, {useRef, useState} from "react";
import {Spin} from "antd";
import {CSVLink} from "react-csv";
import {useDispatch} from "react-redux";

import {ReactComponent as ImportLink} from "../../../assets/icons/import_link.svg";
import {ReactComponent as ExportLink} from "../../../assets/icons/export_sensor_names.svg";

import {axios} from "../../../services/axios";
import {useCurrentSelection} from "../../../hooks/useCurrentSelection";
import {sensorsAC} from "../../../store/branches/sensors/actionCreators";
import {SensorsApi} from "../../../services/api/sensorsApi";

import classes from "../EditSensorsNames.module.scss";

interface HeaderProps {
    deviceTitle?: string
    form?: any
    setRefresh?: (value: boolean) => void
}

const EditNamesHeader: React.FC<HeaderProps> = ({deviceTitle, form, setRefresh}) => {
    const dispatch = useDispatch();
    const {device} = useCurrentSelection();
    const [graphsData, setGraphsData] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const [importLoading, setImportLoading] = useState(false);

    const csvLink = useRef();

    const getGraphsData = async () => {
        setExportLoading(true);
        await SensorsApi.exportCSV(device.id)
            .then((r: any) => {
                setGraphsData(r);
                setExportLoading(false);
            }).catch((e) => {
                console.log(e);
                setExportLoading(false);
            });
        // @ts-ignore
        csvLink?.current?.link.click();
    };


    const handleImport = async (e: any) => {
        setImportLoading(true);
        const getFormData = (doc: any) => {
            const formData = new FormData();
            formData.append("file", doc);
            return formData;
        };
        if (e.target.files && e.target.files.length > 0) {
            const formData = await getFormData(e.target.files[0]);
            const result: any = await axios.post(`api/v1/devices/${device?.id}/settings/import/sensor-names/`, formData);
            if (result?.status === 200) {
                setRefresh(true)
                dispatch(sensorsAC.fetchSensorNames(device?.id.toString()));
            } else {
                setImportLoading(false);
            }
        }
        setImportLoading(false);
    };

    return (
        <div className={classes.sensorsTitle}>
            <div className={classes.sensorsSub}>
                <div/>
                <div>
                    <span>{deviceTitle}</span>
                </div>
            </div>

            <div className={classes.sensorsButtons}>
                <div className={classes.button}>
                    {
                        importLoading
                            ?
                            <Spin/>
                            :
                            <>
                                <label htmlFor="file-upload" className={classes.customFileUpload}>
                                    <ImportLink/>
                                </label>
                                <input id="file-upload" onChange={e => handleImport(e)} type="file"
                                       style={{display: "none"}}/>
                            </>
                    }
                </div>
                <div className={classes.button}>
                    {
                        exportLoading ? <Spin/> : <ExportLink onClick={getGraphsData}/>
                    }
                    <CSVLink
                        data={graphsData}
                        filename={`sensor_names_${device?.device_type}_${device?.id}.csv`}
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                    />
                </div>
            </div>
        </div>
    );
};

export default EditNamesHeader;
