import React from "react";
import {Form} from "antd";

import HeaderFormEditSensorImages from "./HeaderForm";
import SensorForm from "./SensorForm";
import {ImageTypes, SensorImage} from "../../../store/branches/sensors/stateTypes";

import classes from "../EditSensorsImages.module.scss";


interface UserSettingFormProps {
    onSubmit?: (values: any) => void
    onCancel?: () => void
    form?: any
    deviceTitle?: string
    sensorImages?: SensorImage[]
    imageTypes?: ImageTypes[]
    device_type?: string
}

const EditSensorsImagesForm: React.FC<UserSettingFormProps> = ({
                                                                   onSubmit,
                                                                   onCancel,
                                                                   form,
                                                                   imageTypes,
                                                                   sensorImages,
                                                                   device_type,
                                                               }) => {

    return (
        <div className={"header-link"}>
            <Form name="sensor_names"
                  form={form}
                  onFinish={onSubmit}
            >

                <HeaderFormEditSensorImages onCancel={onCancel}/>

                <div className={classes.sensorsImagesInputsWrap}>

                    <div className={classes.container}>
                        {sensorImages?.map((sensorImage: SensorImage, index: number) => (
                            <SensorForm sensorImage={sensorImage}
                                        sensorId={sensorImage?.id}
                                        imageTypes={imageTypes}
                                        key={`sensor_group_name${index}${sensorImage.name}`}
                                        form={form}/>
                        ))}

                    </div>
                </div>
            </Form>
        </div>
    );
};

export default EditSensorsImagesForm;
