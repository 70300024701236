import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {sensorsAC} from "../store/branches/sensors/actionCreators";
import {LoadingStatus} from "../store/status";
import {ApiConfig} from "../services/config";

export const useSocketSensors = (path: string) => {
    const dispatch = useDispatch()
    const [ws, setSocket] = useState(null);

    useEffect(() => {
        const wsServerUrl = ApiConfig.getServerWssUrl();
        const socket = new WebSocket(`${wsServerUrl}${path}`);

        socket.onopen = () => {
            console.warn('WS', 'open');
        }

        socket.onclose = () => {
            console.warn('WS', 'close');
        }

        socket.onerror = (error: any) => {
            console.warn("socket error ", error)
        }

        socket.onmessage = ({data}: any) => {
            dispatch(sensorsAC.setSensorsLoadingStatus(LoadingStatus.LOADING))
            dispatch(sensorsAC.fetchWsSensorsData(JSON.parse(data)))
        }

        setSocket(socket);

        return () => socket.close();
    }, [path, dispatch])

    return ws
}
