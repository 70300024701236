import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectGraphsState} from "../store/selectors";

export const useGetSensorsGraphsArray = () => {
    const {graphsData: graphs} = useSelector(selectGraphsState);
    const [checkedNodes, setCheckedNodes] = useState<string[]>([]);
    const [checkedSensors, setCheckedSensors] = useState<string[]>([]);

    const getExistNode = (node: number) => {
        return checkedNodes?.some(item => item === node.toString());
    };

    const getExistSensor = (sensor: number) => {
        return checkedSensors?.some(item => item === sensor.toString());
    }

    useEffect(() => {
        if (graphs) {
            const initialNodes = [];
            const initialSensors = []
            const keys = Object.keys(graphs?.graphs);

            keys.forEach((item: string) => {
                if (item?.split(".").length === 2) {
                    const nodeId = item?.split(".")[1];
                    initialNodes.push(nodeId)
                }

                graphs?.graphs[item]?.series.forEach((series: any) => {
                    if (series?.sensor) {
                        if (item?.split(".").length === 3) {
                            const nodeKey = item?.split(".");
                            const sensorId = nodeKey[nodeKey.length - 1];

                            initialSensors.push(sensorId);
                        }
                    }
                });
            });
            setCheckedSensors(initialSensors);
            setCheckedNodes(initialNodes);
            return;
        }
    }, [graphs]);

    return {
        checkedSensors,
        getExistSensor,
        getExistNode,
    };
};
