import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "antd";

import {LoadingStatus} from "../../../store/status";
import {selectVerificationState} from "../../../store/selectors";
import {verificationAC} from "../../../store/branches/verification/actionCreators";
import Preloader from "../../../components/Preloader";
import VerificationAlert from "../../../components/Alerts/verification";
import {CustomButton} from "../../../components/Button";

import classes from "../Verification.module.scss";


const ActivationTerms = () => {
    const dispatch = useDispatch();
    const {token} = useParams();
    const {userActivationStatus} = useSelector(selectVerificationState);
    const [isChecked, setChecked] = useState<boolean>(false);

    const handleCheckTerms = () => {
        setChecked((isChecked) => !isChecked);
    };

    const onAccept = () => {
        dispatch(verificationAC.confirmUserActivation({token, stage: 1}));
    };

    return (
        <Preloader isLoaded={userActivationStatus === LoadingStatus.ACTIVATION_USER_LOADING}>
            <div className={classes.termsWrap}>
                <VerificationAlert/>
                <div className={classes.mainTitleWrap}>
                    <h2 className={classes.mainTitle}>Hazardmon Terms of Use</h2>
                </div>

                <div className={classes.termsBlockWrap}>
                    <h3 className={classes.termsSubTitle}>Dear 4B Customer:</h3>
                    <p className={classes.termsContent}>Congratulations on your purchase. 4B appreciates your business
                        and is pleased you have chosen our
                        products to meet your needs.</p>
                    <p className={classes.termsContent}>Please read in its entirety and understand the literature
                        accompanying the product before you
                        place the product into service. Please read the safety precautions carefully before operating
                        the product. With each product you purchase from 4B, there are some basic but important safety
                        considerations you must follow to be sure your purchase is permitted to perform its design
                        function and operate properly and safely, giving you many years of reliable service. Please read
                        and understand the Customer Safety Responsibilities listed below. Failure to follow this safety
                        directive and the Operation Manuals and other material furnished or referenced, may result in
                        serious injury or death. </p>
                </div>

                <div className={classes.termsBlockWrap}>
                    <h3 className={classes.termsSubTitle}>SAFETY NOTICE TO OUR CUSTOMERS</h3>
                    <p className={classes.termsContent}>A. In order to maximize efficiency and safety, selecting the
                        right equipment for each operation is vital. The proper installation of the equipment, and
                        regular maintenance and inspection is equally important in continuing the proper operation and
                        safety of the product. The proper installation and maintenance of all our products is the
                        responsibility of the user unless you have asked 4B to perform these tasks.</p>
                    <p className={classes.termsContent}>B. All installation and wiring must be in accordance with Local
                        and National Electrical Codes and other standards applicable to your industry. (Please see the
                        article "Hazard Monitoring Equipment Selection, Installation and Maintenance" at www.go4b.com.)
                        The installation of the wiring should be undertaken by an experienced and qualified professional
                        electrician. Failure to correctly wire any product and/or machinery can result in the product or
                        machine failing to operate as intended, and can defeat its design function.</p>
                    <p className={classes.termsContent}>C. Periodic inspection by a qualified person will help assure
                        your 4B product is performing properly. 4B recommends a documented inspection at least annually
                        and more frequently under high use conditions.</p>
                </div>

                <div className={classes.termsBlockWrap}>
                    <span className={classes.termsRule}>WARNING</span>
                    <p className={classes.termsContent}>HazardMon.com is intended for viewing the status of the
                        installed hazard monitoring system only. It should not be used as a safety system. The hazard
                        monitoring system installed on site MUST provide automatic alarms and shutdowns of monitored
                        equipment. </p>
                </div>

                <div className={classes.termsBlockWrap}>
                    <span className={classes.termsRule}>WARNING</span>
                    <p className={classes.termsContent}>HazardMon.com is a webpage subscription for use by the plant
                        owner. The webpage allows the user to remotely view the status of their 4B hazard monitoring
                        system. 4B does NOT provide any type of monitoring service. The monitoring and safety of the
                        plant is the responsibility of the user.</p>
                </div>

                <div className={classes.termsBlockWrap}>
                    <span className={classes.termsRule}>CUSTOMER SAFETY RESPONSIBILITIES</span>
                </div>

                <div className={classes.termsBlockWrap}>
                    <span className={classes.termsRule}>1. READ ALL LITERATURE PROVIDED WITH YOUR PRODUCT</span>

                    <p className={classes.termsContent}>Please read all user, instruction and safety manuals to ensure
                        that you understand your product operation and are able to safely and effectively use this
                        product.</p>

                    <span className={classes.termsRule}>2. YOU BEST UNDERSTAND YOUR NEEDS</span>
                    <p className={classes.termsContent}>Every customer and operation is unique, and only you best know
                        the specific needs and capabilities of your operation. Please call the 24-hour hotline at
                        309-698-5611 for assistance with any questions about the performance of products purchased from
                        4B. 4B is happy to discuss product performance with you at any time. </p>
                    <span className={classes.termsRule}>3. SELECT A QUALIFIED AND COMPETENT INSTALLER</span>
                    <p className={classes.termsContent}>Correct installation of the product is important for safety and
                        performance. If you have not asked 4B to perform the installation of the unit on yo those who
                        may perform work on your operation that you select a qualified and competent electrical
                        installer to undertake the installation. The p functions. The installer should be qualified,
                        trained, and competent to perform the installation in accordance with Local and National
                        Electrical your own standards and preventive maintenance requirements, and other product
                        installation information supplied with the product. You shoulc installation information to
                        assist in the installation. </p>
                    <span className={classes.termsRule}>4. ESTABLISH AND FOLLOW A REGULAR MAINTENANCE AND INSPECTION
                        SCHEDULE FOR YOU</span>
                    <p className={classes.termsContent}>You should develop a proper maintenance and inspection program
                        to confirm that your system is in good working order at all times. You will be frequency for
                        inspection. Many different factors known to the user will assist you in deciding the frequency
                        of inspection. These factors may inc construction work at the facility; hours of operation;
                        animal or insect infestation; and the real-world experience of knowing how your employees to
                        install, operate, maintain, inspect or perform any work whatsoever, should be trained and
                        qualified to perform these important functions. Cor inspection process should be created and
                        retained by you at all times.</p>
                    <span className={classes.termsRule}>5. RETAIN AND REFER TO THE OPERATION MANUAL FOR 4B'S SUGGESTED
                        MAINTENANCE ANI</span>
                    <p className={classes.termsContent}>As all operations are different, please understand that your
                        specific operation may require additional adjustments in the maintenance and inspc to perform
                        its intended function. Retain the Operation Manual and other important maintenance and service
                        documents provided by 4B and ha equipment. Should you have any question</p>

                    <span className={classes.termsRule}>6. SERVICE REQUEST</span>
                    <p className={classes.termsContent}>If you have questions or comments about the operation of your
                        unit or require the unit to be serviced please contact the 4B location who suppli 5615), email
                        (<a href="mailto:4b-usa@go4b.com" className={classes.link}>4b-usa@go4b.com</a>), or call us via
                        our 24-hour hotline
                        number in
                        the USA (309-698-5611). Please
                        have available product part nu' installation. In order to assist you, after the product has been
                        placed into service, complete the online product registration section which is acct</p>
                </div>

                <div className={classes.checkboxWrap}>
                    <Checkbox checked={isChecked}
                              onChange={handleCheckTerms}
                              className={classes.checkbox}
                    />
                    <span className={classes.checkboxText} onClick={handleCheckTerms}>I have read and agree to the Terms & Conditions*</span>
                </div>

                <div>
                    <CustomButton width="150px"
                                  height="52px"
                                  padding="0"
                                  htmlType="button"
                                  onClick={onAccept}
                                  disabled={!isChecked}
                    >
                        <span>ACCEPT</span>
                    </CustomButton>
                </div>
            </div>
        </Preloader>
    );
};

export default ActivationTerms;
