import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import {Typography} from "antd";

import {sitesAC} from "../../store/branches/sites/actionCreators";
import EditSiteInputs from "../EditSiteInputs";
import HeaderFormEditSite from "./components/HeaderForm";
import SitesAlert from "../Alerts/sites";
import {LoadingStatus} from "../../store/status";
import Preloader from "../Preloader";
import {useCurrentSelection} from "../../hooks/useCurrentSelection";
import {selectSitesState} from "../../store/selectors";

import classes from "./EditSite.module.scss";
import {usePermissions} from "../../hooks/usePermissions";

const {Title} = Typography;

const EditSite: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isModal, setModal] = useState<boolean>(false);
    const [isDeactivateModal, setDeactivateModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState({page: 1, pageSize: 20});


    const {current_location: currentLocation} = useSelector(selectSitesState);
    const {timezones} = useSelector(selectSitesState);
    const {status} = useSelector(selectSitesState);
    const {client} = useCurrentSelection();
    const {hasAccessToEdit} = usePermissions();

    const {id}: any = useParams();

    const onDeactivateSite = () => {
        dispatch(sitesAC.deactivateCurrentLocation(id));
        dispatch(sitesAC.changeActivateCurLoc(!currentLocation?.is_suspended));
        setDeactivateModal(false);
    };

    const onRemoveSite = () => {
        dispatch(sitesAC.removeCurrentLocation(id));
    };

    const onChangePagination = useCallback((page: number, pageSize: number) => {
        setPagination({page, pageSize});
    }, []);


    useEffect(() => {
        dispatch(sitesAC.fetchAssignUsers({
            location_id: id,
            offset: (pagination.page - 1) * pagination.pageSize,
        }));
    }, [dispatch, id, pagination]);

    useEffect(() => {
        if (!timezones) {
            dispatch(sitesAC.fetchTimezones());
        }
    }, [dispatch, timezones]);

    useEffect(() => {
        dispatch(sitesAC.fetchCurrentLocation(id));
    }, [dispatch, id]);

    useEffect(() => {

        return () => {
            dispatch(sitesAC.clearAssignUsers());
        };
    }, [dispatch]);

    const onOpenModal = () => {
        setModal(true);
    };

    const onOpenDeactivateModal = () => {
        setDeactivateModal(true);
    };

    const onCancelModal = () => {
        setModal(false);
    };
    const onCancelDeactivateModal = () => {
        setDeactivateModal(false);
    };

    if (!hasAccessToEdit) {
        history.push("/sites");
    }

    return (
        <Preloader isLoaded={status === LoadingStatus.LOADING}>
            <React.Fragment>
                <SitesAlert/>


                <div className={clsx("header-link", classes.wrap)}>
                    <Title level={5}> <Link to="/sites">{client?.company}/</Link> {currentLocation?.title || "Site"}
                    </Title>

                    <HeaderFormEditSite onRemoveSite={onOpenModal}
                                        onDeactivateSite={onOpenDeactivateModal}
                                        is_suspended={currentLocation?.is_suspended}
                                        currentLocation={currentLocation}

                    />

                    <EditSiteInputs onRemoveSite={onRemoveSite}
                                    onDeactivateSite={onDeactivateSite}
                                    onCancelModal={onCancelModal}
                                    onCancelDeactivateModal={onCancelDeactivateModal}
                                    page={pagination?.page}
                                    onChangePagination={onChangePagination}
                                    isModal={isModal}
                                    is_suspended={currentLocation?.is_suspended}
                                    isDeactivateModal={isDeactivateModal}
                    />

                </div>
            </React.Fragment>
        </Preloader>

    );
};
export default EditSite;
