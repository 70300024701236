class ConfigApiUrl {
    getServerApiUrl() {
        return process.env.REACT_APP_API_SERVER_URL ? process.env.REACT_APP_API_SERVER_URL : `https://${window?.location?.host}/`
    }

    getServerWssUrl() {
        return process.env.REACT_APP_WS_SERVER_URL ? process.env.REACT_APP_WS_SERVER_URL : `wss://${window.location.host}/`
    }
}

export const ApiConfig = new ConfigApiUrl()

