import React from "react";
import classes from "../Verification.module.scss";
import clsx from "clsx";

type RuleItemType = {
    error?: boolean
    rule: string
}

export const RuleItem: React.FC<RuleItemType> = ({error, rule}) => {
    return (
        <div className={clsx(classes.ruleWrap, error ? classes.error : '')}>
            <div className={classes.dote}/>
            <div className={classes.rule}>
                <span>{rule}</span>
            </div>
        </div>
    );
};
