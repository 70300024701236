import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "antd/lib/form/Form";
import {useDispatch, useSelector} from "react-redux";
import Empty from "antd/lib/empty";

import {sensorsAC} from "../../store/branches/sensors/actionCreators";
import {useCurrentSelection} from "../../hooks/useCurrentSelection";
import {LoadingStatus} from "../../store/status";
import DashboardAlert from "../Alerts/dachboard";
import {selectSensorsState} from "../../store/selectors";
import Spinner from "../Spinner";
import EditSensorsImagesForm from "./components/EditSensorsImagesForm";
import {usePermissions} from "../../hooks/usePermissions";


const EditSensorsImages: React.FC = () => {
    const [form] = useForm();
    const history = useHistory();
    const dispatch = useDispatch();

    const {device} = useCurrentSelection();
    const {hasAccessToEdit} = usePermissions()
    const {status, sensorImages, imageTypes} = useSelector(selectSensorsState);

    const onSubmit = (values: any) => {
        const keys = Object.keys(values);
        const data = {};
        keys.forEach(item => {
            if (typeof values[item] === "number" || values[item] === null) {
                data[item] = values[item];
                return;
            }

            if(imageTypes){
                imageTypes.forEach(typeObj => {
                    if(typeObj?.name === values[item]?.props?.text){
                        data[item] = typeObj?.id
                    }
                })
            }
            return;
        });

        dispatch(sensorsAC.addSensorImages({device_id: device?.id.toString(), data: {sensor_image_types: data}}));
    };


    const onCancel = () => {
        form.resetFields();
        history.push("/dashboard");
    };

    useEffect(() => {
        if (!device) {
            dispatch(sensorsAC.setSensorsStatusOperation(LoadingStatus.FETCH_SENSORS_WITHOUT_DEVICE));
            return;
        }

        dispatch(sensorsAC.fetchSensorImages(device?.id.toString()));

        return () => {
            dispatch(sensorsAC.setImageTypes(null));
            dispatch(sensorsAC.setSensorImages(null));
        };
    }, [dispatch, device]);

    if (status === LoadingStatus.LOADING) {
        return <Spinner/>;
    }

    if(!hasAccessToEdit){
        history.push('/dashboard')
    }

    if (sensorImages && imageTypes) {
        return (
            <React.Fragment>
                <DashboardAlert/>

                <EditSensorsImagesForm form={form}
                                       device_type={device.device_type}
                                       deviceTitle={device?.title}

                                       sensorImages={sensorImages}
                                       imageTypes={imageTypes}
                                       onSubmit={onSubmit}
                                       onCancel={onCancel}
                />
            </React.Fragment>
        );
    }
    return <Empty description="Sensor names is empty!"/>;
};
export default EditSensorsImages;
