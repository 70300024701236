import React from "react";
import {BackTop} from "antd";
import {Redirect, useParams} from "react-router-dom";

import arrowUp from "../../assets/icons/arrowUp.svg";

import LandingHeader from "../../components/LandingHeader";
import LandingFooter from "../../components/LandingFooter";
import VerificationAlert from "../../components/Alerts/verification";
import Preloader from "../../components/Preloader";

import classes from "../Verification/Verification.module.scss";
import PasswordForm from "../Verification/components/PasswordForm";
import {LoadingStatus} from "../../store/status";
import {useSelector} from "react-redux";
import {selectVerificationState} from "../../store/selectors";
import {backToTopStyle} from "../Verification";


export const ResetPassword: React.FC = () => {
    const {id} = useParams();
    const {userActivationStatus} = useSelector(selectVerificationState);
    if (userActivationStatus === LoadingStatus.CHANGE_PASSWORD_SUCCESS) {
        return <Redirect to={{pathname: "/"}}/>;
    }

    return (
        <Preloader isLoaded={userActivationStatus === LoadingStatus.ACTIVATION_USER_LOADING}>
            <div>
                <div className={classes.headerBlock}>
                    <LandingHeader activationLink={!!id} onLogin={() => {
                    }} isRegister={false}/>
                    <VerificationAlert/>

                    <div className={classes.content}>
                        <div className={classes.informationBlockWrap}>
                            <PasswordForm/>
                        </div>
                    </div>

                    <BackTop visibilityHeight={0}>
                        <img src={arrowUp} style={backToTopStyle} alt="arrowUp"/>
                    </BackTop>
                </div>
                <div>
                    <LandingFooter/>
                </div>
            </div>
        </Preloader>
    );
};
