import React from "react";

import classes from "../EditSensorsImages.module.scss";

interface CustomOptionProps {
    imageUrl?: string
    text?: string
}

const CustomOption: React.FC<CustomOptionProps> = ({imageUrl, text}) => {
    return (
        <div className={classes.selectImageWrap}>
            <div className={classes.selectImage}>
                <img src={imageUrl} alt="select"
                     className={classes.image}/>
            </div>
            <div className={classes.selectText}>
                <span className={classes.text}>{text}</span>
            </div>
        </div>
    );
};
export default CustomOption
