import axios from "axios";
import {WinStorage} from "./AuthSrorage";
import history from "../helpers/history";
import {ApiConfig} from "./config";

axios.interceptors.request.use((config) => {
    const apiSeverUrl = ApiConfig.getServerApiUrl();
    config.baseURL = apiSeverUrl;
    const token = window.localStorage.getItem("_token");
    if (token) {
        config.headers["Authorization"] = `JWT ${token}`;
    }
    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 400) {
        const message: any = Object.values(error.response.data)[0];
        const warning: any = Object.values(error.response.data);
        return WinStorage.setErrorMessage(warning[0] || message);
    }
    if (error.response.status === 401) {
        WinStorage.removeToken();
        history.push("/");
    }
    if (error.response.status === 403) {
        console.log(error.response.data.detail);
        const message: any = Object.values(error.response.data)[0];
        WinStorage.setErrorMessage(message);
    }
    if (error.response.status === 404) {
        console.log("404 Server not found!");
        const message: any = Object.values(error.response.data)[0];
        WinStorage.setErrorMessage(message);
    }
    return error;
});
export {axios};
