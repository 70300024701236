// @ts-nocheck
import React, {useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {LoadingStatus} from "../../store/status";
import {userAC} from "../../store/branches/user/actionCreators";
import {WinStorage} from "../../services/AuthSrorage";
import {usePermissions} from "../../hooks/usePermissions";
import {selectUserState} from "../../store/selectors";

import {LandingPage} from "../../pages/LandingPage";
import LayoutComponent from "../Layout";
import Preloader from "../Preloader";
import {adminRoutes, privateRoutes} from "./components/routes";
import {ACTIVATE_ACCOUNT, CHANGE_PASSWORD, LANDING_PAGE} from "./components/constants";
import SendFeedback from "../SendFeedback";
import {UserVerification} from "../../pages/Verification";
import {ResetPassword} from "../../pages/ResetPassword";


interface RouteProps {
    path: string | string[];
    Component: React.FC<any>;
}

const PrivateRoute: React.FC<{ isTablet: boolean }> = ({isTablet}) => {

    const location = useLocation();
    const dispatch = useDispatch();

    const token = WinStorage.getToken();
    const {auth_key} = useSelector(selectUserState);
    const {status} = useSelector(selectUserState);
    const isReady = status === LoadingStatus.LOADING;

    const {isSuperUser, isOEM, isManager, isAccountManager, isEngineer} = usePermissions();

    useEffect(() => {
        if (auth_key) {
            if (auth_key !== token) {
                dispatch(userAC.logOut());
            }
        }
    }, [location, auth_key, dispatch, token]);

    return (
        <React.Fragment>
            {token
                ?
                <Switch>
                    <Route path={ACTIVATE_ACCOUNT} component={UserVerification}/>
                    <Preloader isLoaded={isReady && !auth_key}>
                        {auth_key && (
                            <>
                                <LayoutComponent isTablet={isTablet}>
                                    {location.pathname === "/" && <Redirect to="/sites"/>}

                                    {privateRoutes.map(({path, Component}: RouteProps) =>
                                        <Route key={path + "private"} path={path} component={Component} exact/>,
                                    )}

                                    {(isSuperUser || isOEM || isManager || isAccountManager || isEngineer) && adminRoutes.map(({
                                                                                                                                   path,
                                                                                                                                   Component,
                                                                                                                               }: RouteProps) =>
                                        <Route key={path + "admin"} path={path} component={Component} exact/>,
                                    )}
                                </LayoutComponent>
                            </>
                        )}
                    </Preloader>
                </Switch>
                :
                <Switch>
                    <Route path={ACTIVATE_ACCOUNT} component={UserVerification}/>
                    <Route path={CHANGE_PASSWORD} component={ResetPassword}/>
                    <Route path="/feedback" component={SendFeedback}/>
                    <Route path={"/"} component={LandingPage}/>
                    <Redirect to={LANDING_PAGE}/>
                </Switch>}
        </React.Fragment>
    );
};
export default PrivateRoute;
