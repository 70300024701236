import React, {useEffect} from "react";
import {BackTop} from "antd";
import {Redirect, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import arrowUp from "../../assets/icons/arrowUp.svg";

import LandingHeader from "../../components/LandingHeader";
import LandingFooter from "../../components/LandingFooter";

import {selectUserState, selectVerificationState} from "../../store/selectors";
import {LoadingStatus} from "../../store/status";
import {verificationAC} from "../../store/branches/verification/actionCreators";
import ActivationTerms from "./components/ActivationTerms";
import PasswordForm from "./components/PasswordForm";

import classes from "./Verification.module.scss";
import VerificationAlert from "../../components/Alerts/verification";
import Preloader from "../../components/Preloader";

export const backToTopStyle: React.CSSProperties = {
    height: 45,
    width: 45,
    lineHeight: "40px",
    borderRadius: 50,
    backgroundColor: "#A9CC13",
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
};

export const UserVerification: React.FC = () => {
    const dispatch = useDispatch();
    const {auth_key} = useSelector(selectUserState);
    const {userActivationStatus, userActivation} = useSelector(selectVerificationState);
    const {token} = useParams();

    useEffect(() => {
        if (token) {
            dispatch(verificationAC.getUserActivation(token));
        }
    }, [token, dispatch]);

    if (userActivationStatus === LoadingStatus.ACTIVATION_USER_ERROR) {
        if(auth_key){
            return <Redirect to={{pathname: "/sites"}}/>;
        }
        return <Redirect to={{pathname: "/"}}/>;
    }

    if (userActivationStatus === LoadingStatus.ACTIVATION_USER_PASSWORD_SUCCESS) {
        if(auth_key){
            return <Redirect to={{pathname: "/sites"}}/>;
        }
        return <Redirect to={{pathname: "/"}}/>;
    }

    return (
        <Preloader isLoaded={userActivationStatus === LoadingStatus.ACTIVATION_USER_LOADING}>
            <div>
                <div className={classes.headerBlock}>
                    <LandingHeader activationLink={!!token} onLogin={() => {
                    }} isRegister={false}/>
                    <VerificationAlert/>

                    <div className={classes.content}>
                        <div className={classes.informationBlockWrap}>
                            {
                                userActivation?.stage === 1
                                    ?
                                    <PasswordForm/>
                                    :
                                    <ActivationTerms/>
                            }
                        </div>
                        {
                            userActivation?.stage === 0
                            &&
                            <div style={{marginTop: "150px"}}>
                                <LandingFooter/>
                            </div>
                        }

                    </div>

                    <BackTop visibilityHeight={0}>
                        <img src={arrowUp} style={backToTopStyle} alt="arrowUp"/>
                    </BackTop>
                </div>

                {
                    userActivation?.stage !== 0
                    &&
                    <div>
                        <LandingFooter/>
                    </div>
                }
            </div>
        </Preloader>
    );
};
