import {ImageTypes, SensorImage, SensorImageForSelectField} from "../store/branches/sensors/stateTypes";


export const useGetSensorIcon = ({sensorImages, groupId, sensor, imageTypes, groupNameF500, device, isAlignment}) => {

    const getSensorIconId = () => {
        if (sensorImages) {
            let currentGroup;
            if (isAlignment) {
                currentGroup = sensorImages?.find((item: SensorImage) => item?.name === `Alignment:${groupNameF500}`);
            } else if (device.device_type === "F500-UDF") {
                currentGroup = sensorImages?.find((item: SensorImage) => item?.name === groupNameF500);
            } else {
                currentGroup = sensorImages?.find((item: SensorImage) => item?.id.toString() === groupId);
            }
            if (currentGroup) {
                const currentSensor = currentGroup?.sensors?.find((item: SensorImageForSelectField) => Object.keys(item)[0] === sensor?.Id);
                if (currentSensor) {
                    return Object.values(currentSensor)[0] ? Object.values(currentSensor)[0] : "default";
                }
            }
            return "default";
        }
        return "default";
    };

    const sensorIconId = getSensorIconId();
    const getIconUrl = () => {
        if (imageTypes && sensorIconId !== "default") {
            const currentIcon = imageTypes.filter((item: ImageTypes) => item?.id === sensorIconId);
            return currentIcon[0];
        }
        return "default";
    };
    const sensorIcon = getIconUrl();

    return {sensorIcon};
};
