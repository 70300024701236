import {
    ConfirmUserActivationAI, GetUserActivationAI, SetChangePasswordAI, SetUserActivationAI,
    SetUserActivationPasswordAI, SetUserActivationStatusAI, VerificationAT,
} from "./actionTypes";


export const verificationAC = {
    getUserActivation: (payload): GetUserActivationAI => ({
        type: VerificationAT.GET_USER_ACTIVATION,
        payload,
    }),
    confirmUserActivation: (payload): ConfirmUserActivationAI => ({
        type: VerificationAT.CONFIRM_USER_ACTIVATION,
        payload,
    }),
    setUserActivation: (payload): SetUserActivationAI => ({
        type: VerificationAT.SET_USER_ACTIVATION,
        payload,
    }),
    setUserActivationStatus: (payload): SetUserActivationStatusAI => ({
        type: VerificationAT.SET_USER_ACTIVATION_STATUS,
        payload,
    }),
    setUserActivationPassword: (payload): SetUserActivationPasswordAI => ({
        type: VerificationAT.SET_USER_ACTIVATION_PASSWORD,
        payload,
    }),
    setChangePassword: (payload): SetChangePasswordAI => ({
        type: VerificationAT.SET_CHANGE_PASSWORD,
        payload,
    }),
};

export type VerificationActions =
    GetUserActivationAI
    | ConfirmUserActivationAI
    | SetUserActivationAI
    | SetUserActivationStatusAI
    | SetUserActivationPasswordAI
    | SetChangePasswordAI
