import produce, {Draft} from "immer";
import {LoadingStatus} from "../../status";
import { VerificationActions } from "./actionCreators";
import { VerificationAT } from "./actionTypes";
import {VerificationState} from "./stateTypes";

const initialVerificationState: VerificationState = {
    userActivation: {stage: 0},
    userActivationStatus: LoadingStatus.ACTIVATION_USER_PENDING,
};

export const verificationReducer = produce((draft: Draft<VerificationState>, action: VerificationActions) => {
    switch (action.type) {
        case VerificationAT.SET_USER_ACTIVATION_STATUS:
            draft.userActivationStatus = action.payload;
            break;

        case VerificationAT.SET_USER_ACTIVATION:
            draft.userActivation = action.payload;
            break;

        default:
            break;
    }
}, initialVerificationState);
