export const LANDING_PAGE = "/"
export const CLIENTS = ["/", "/clients", "/clients/:search"]
export const ADD_CLIENT = "/clients/add/client"
export const EDIT_CLIENT = "/clients/add/client/:id"
export const SITES = "/sites"
export const EDIT_SITES = "/sites/edit/:id"
export const ADD_SITE = "/sites/add/site"
export const EDIT_WARNINGS = "/dashboard/edit/warnings"
export const HISTORICAL = ["/dashboard/historical/:times", "/dashboard/historical"]
export const EDIT_SENSORS_NAMES = "/edit/sensor/names"
export const EDIT_SENSORS_IMAGES = "/edit/sensor/images"
export const EDIT_SENSORS_TYPES = "/edit/sensor/types"
export const USER_SETTING = "/user/setting"
export const ADD_USER = "/sites/add/user"
export const EDIT_USER = "/sites/edit/assign/user/:id"
export const DEVICES = "/devices"
export const ADD_DEVICE = "/devices/add/device"
export const EDIT_DEVICE = "/devices/edit/device/:id"
export const CURRENT_DEVICES = "/devices/:id"
export const SENSOR_DASHBOARD = ["/dashboard", "/dashboard/historical/snapshot/:time"]
export const FEEDBACK = "/feedback"
export const SENSOR_GRAPHS = "/graphs"
export const ACTIVATE_ACCOUNT = "/accounts/activate/:token"
export const CHANGE_PASSWORD = "/password/reset/key/:id"
export const SENSOR_GRAPHS_BY_ID = "/graphs/:sensorId"
export const CUSTOM_HISTORICAL_GRAPHS = ["/graphs/historical/graphs", "/graphs/historical/graphs/:times/:id?"]
export const NOTIFICATIONS = ["/notifications", "/notifications/all"]
export const ANALYTICS = "/analytics"
export const VISUAL_DASHBOARD = "/visual-dashboard/site/:siteId/schemas/"
export const VISUAL_DASHBOARD_EDITOR = "/visual-dashboard/site/:siteId/schemas/new"
export const EMAIL_NOTIFICATIONS = "/user/setting/notification"
export const ADD_EMAIL_NOTIFICATIONS = "/user/setting/add/notification"
export const EDIT_EMAIL_NOTIFICATIONS = "/user/setting/add/notification/:id"
export const MODIFY_HOMEPAGE = "/modify-homepage"
export const OEM_SETTING = "/oem/setting"
export const OEM_SETTING_RSS_EDIT = "/settings/rss/edit"
export const OEM_SETTING_CONTACTS = "/settings/oem/contacts"
export const OEM_PAGE_CSS = "/flatpages/"
