import React from "react";

import classes from "./InputWrap.module.scss";
import clsx from "clsx"
import { Tooltip } from "antd";


interface EditProps {
    title?: string
    children?: any
    className?: any
    classNameTitle?: any
    subTitle?: any
}


const InputWrap: React.FC<EditProps> = ({title, children, className, classNameTitle, subTitle}) => {
    return (
        <div className={classes.block}>
            <div className={clsx(classes.titleWrap, classNameTitle)}>
                <Tooltip placement="topLeft" title={title}>
                    <span className={classes.title}>{title}</span>
                </Tooltip>
            </div>

            <div className={clsx(classes.input, className)}>
                {children}
            </div>
            {
                subTitle
                &&

                <div className={clsx(classes.input, className)}>
                    {subTitle}
                </div>
            }
        </div>
    )
}
export default InputWrap
