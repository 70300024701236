import {call, put, takeLatest} from "redux-saga/effects";
import {LoadingStatus} from "../../status";
import {
    AddF500GraphAI,
    FetchCustomGraphsDataAI,
    FetchF500NodesAI,
    FetchGraphsDataAI,
    GetLiveGraphsDataAI,
    GraphsAT,
    UpdateLiveGraphsDataAI,
} from "./actionTypes";
import {GraphsApi} from "../../../services/api/graphsApi";
import {graphsAC} from "./actionCreators";
import {SensorsApi} from "../../../services/api/sensorsApi";


export function* fetchGraphsDataRequest({payload}: FetchGraphsDataAI) {
    try {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.LOADING));
        const data = yield call(GraphsApi.getGraphs, payload);
        yield put(graphsAC.setGraphsData(data));
    } catch (error) {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
        yield put(graphsAC.setGraphsStatusOperation(LoadingStatus.FETCH_GRAPHS_ERROR));
    }
}

export function* fetchF500NodesRequest({payload}: FetchF500NodesAI) {
    try {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.LOADING));
        const data = yield call(SensorsApi.getF500Nodes, payload);
        yield put(graphsAC.setF500Nodes(data));
    } catch (error) {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
        yield put(graphsAC.setGraphsStatusOperation(LoadingStatus.FETCH_GRAPHS_ERROR));
    }
}


export function* liveGraphsDataRequest({payload}: GetLiveGraphsDataAI) {
    try {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.LOADING));
        const data = yield call(GraphsApi.getLiveGraphsData, payload);
        if (data) {
            yield put(graphsAC.setGraphsData(data));
        } else {
            yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
            yield put(graphsAC.setGraphsStatusOperation(LoadingStatus.FETCH_GRAPHS_ERROR));
        }
    } catch (error) {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
        yield put(graphsAC.setGraphsStatusOperation(LoadingStatus.FETCH_GRAPHS_ERROR));
    }
}

export function* updateGraphsDataRequest({payload}: UpdateLiveGraphsDataAI) {
    try {
        const data = yield call(GraphsApi.getLiveGraphsData, payload);
        if (data) {
            yield put(graphsAC.updateOldGraphsData(data));
        } else {
            yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
            yield put(graphsAC.setGraphsStatusOperation(LoadingStatus.FETCH_GRAPHS_ERROR));
        }
    } catch (error) {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
        yield put(graphsAC.setGraphsStatusOperation(LoadingStatus.FETCH_GRAPHS_ERROR));
    }
}


export function* fetchCustomGraphsDataRequest({payload}: FetchCustomGraphsDataAI) {
    try {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.LOADING));
        const data = yield call(GraphsApi.getCustomGraphs, payload);
        yield put(graphsAC.setGraphsData(data));
    } catch (error) {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
        yield put(graphsAC.setGraphsStatusOperation(LoadingStatus.FETCH_GRAPHS_ERROR));
    }
}


export function* addF500GraphRequest({payload}: AddF500GraphAI) {
    try {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.LOADING));
        const data = yield call(GraphsApi.addF500Graph, {
            action: payload.data.action,
            node: payload.data.node,
            sensor: payload.data.sensor,
            device_id: payload.data.device_id,
            graph_type: "update_graphs_arrangement"
        });

        if (data.status === 200) {
            const graphsData = yield call(GraphsApi.getGraphs, {
                device_id: payload?.data.device_id,
                timescale: payload?.timescale
            });

            if (graphsData) {
                yield put(graphsAC.setGraphsData(graphsData));
                yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.SUCCESS));
            } else {
                yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
            }
        } else {
            yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
        }
    } catch {
        yield put(graphsAC.setGraphsLoadingStatus(LoadingStatus.ERROR));
    }
}


export function* graphsSaga() {
    yield takeLatest(GraphsAT.FETCH_GRAPHS_DATA, fetchGraphsDataRequest);
    yield takeLatest(GraphsAT.FETCH_F500_NODES, fetchF500NodesRequest);
    yield takeLatest(GraphsAT.FETCH_CUSTOM_GRAPHS_DATA, fetchCustomGraphsDataRequest);
    yield takeLatest(GraphsAT.GET_LIVE_GRAPHS_DATA, liveGraphsDataRequest);
    yield takeLatest(GraphsAT.UPDATE_GRAPHS_DATA, updateGraphsDataRequest);
    yield takeLatest(GraphsAT.ADD_F500_GRAPH, addF500GraphRequest);
}
